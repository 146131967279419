import React from 'react'
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './Footer';
import user from '../Image/user.png';

const AcceptOrder = () => {
  return (
    <div>
        {<Sidebar/>}
        <div className="asside">
            <div className='about-first'>
                <div className="row">
                    <div className="col-12">
                        <div className='page-heading'>
                            <h3>Accept Order</h3>
                        </div>
                        <div className="bg-box">
                            <div className="pro-add-new px-0">
                                <p># Table No : <span>2  Aakash Kukadiya</span></p>
                            </div>
                            <div className='responsive-table'>
                                <table className="table">
                                    <tbody>
                                        <tr className='shadow-border'>
                                            <td>
                                                <div className='profile-img'>
                                                    <img src={user} className='img-fluid'/>
                                                    <p>Jalebi & Fafda</p>
                                                </div>
                                            </td>
                                            <td>X3</td>
                                            <td>
                                                <div className='accept-order'>
                                                    <p>Accept Order</p>
                                                </div>
                                            </td>
                                            <td>₹ 180</td>
                                        </tr>
                                        <tr className='shadow-border'>
                                            <td>
                                                <div className='profile-img'>
                                                    <img src={user} className='img-fluid'/>
                                                    <p>Jalebi & Fafda</p>
                                                </div>
                                            </td>
                                            <td>X3</td>
                                            <td>
                                                <div className='reject-order'>
                                                    <p>Reject Order</p>
                                                    <h6>Item out of Stock</h6>
                                                </div>
                                            </td>
                                            <td>₹ 180</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pro-add-new px-0">
                                <p># Table No : <span>2  Aakash Kukadiya</span></p>
                            </div>
                            <div className='responsive-table'>
                                <table className="table">
                                    <tbody>
                                        <tr className='shadow-border'>
                                            <td>
                                                <div className='profile-img'>
                                                    <img src={user} className='img-fluid'/>
                                                    <p>Jalebi & Fafda</p>
                                                </div>
                                            </td>
                                            <td>X3</td>
                                            <td>
                                                <div className='accept-order'>
                                                    <p>Accept Order</p>
                                                </div>
                                            </td>
                                            <td>₹ 180</td>
                                        </tr>
                                        <tr className='shadow-border'>
                                            <td>
                                                <div className='profile-img'>
                                                    <img src={user} className='img-fluid'/>
                                                    <p>Jalebi & Fafda</p>
                                                </div>
                                            </td>
                                            <td>X3</td>
                                            <td>
                                                <div className='reject-order'>
                                                    <p>Reject Order</p>
                                                    <h6>Item out of Stock</h6>
                                                </div>
                                            </td>
                                            <td>₹ 180</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AcceptOrder