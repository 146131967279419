import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import sendmailgif from "../Image/sent-mail.gif";
import { useForm } from "react-hook-form";
import { AdminEmailLinkApi } from "../helper/integration";
import toast from "react-hot-toast";

const EmailVerification = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onLink = async (data) => {
    try {
      const response = await AdminEmailLinkApi(data.email);
      console.log("API response:", response);
      // Trim response data to remove any extra whitespace or newline characters
      const responseData = response.data;
      if (responseData === "Email sent successfully\r\n") {
        navigate("/Successfullysentmail");
        console.log("Navigation successful", response);
        toast.success("Email sent successfully");
      } else {
        toast.error("failed to send verification email. Please try again.");
      }
    } catch (error) {
      console.log("API error", error);
      //toast.error("email is not registerd.");
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2 className="mb-0">Email Verification</h2>
                </div>
                <div className="col-4 text-end">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleSubmit(onLink)}>
                <div className="form-floating mb-4">
                  <input
                    type="mail"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  <label for="floatingInput">Enter Your Email</label>
                </div>
                <button
                  to={"/Successfullysentmail"}
                  type="submit"
                  className="btn btn-login"
                >
                  Email Verify
                </button>
              </form>
              <div className="create-acc">
                <p>
                  Already have an account?{" "}
                  <Link to={"/"}> Sign in instead</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
