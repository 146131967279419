export const Main ="https://restaurant-api.4born.in";     //"http://localhost:5000"; //"http://localhost:3001";

export const Api = {
  LoginApi: `${Main}/api/admin/admin_login`,
  EmailLinkApi: `${Main}/api/admin/admin_email_link`,
  ForgotPasswordApi: `${Main}/api/admin/admin_forgot_pass`,

  ShowTableApi: `${Main}/api/admin/admin_show_table`,
  AddTableApi: `${Main}/api/admin/admin_add_table`,
  EditTableApi: `${Main}/api/admin/admin_edit_table`,
  DeleteTableApi: `${Main}/api/admin/admin_delete_table`,

  ShowItemsApi: `${Main}/api/admin/admin_show_item`,
  DeleteItemsApi: `${Main}/api/admin/admin_delete_item`,
  AddItemsApi: `${Main}/api/admin/admin_add_item`,
  EditItemsApi: `${Main}/api/admin/admin_edit_item`,

  ShowCategoryApi: `${Main}/api/admin/admin_category`,
  EditCategoryApi: `${Main}/api/admin/admin_edit_category`,
  DeleteCategoryApi: `${Main}/api/admin/admin_delete_category`,
  AddCategoryApi: `${Main}/api/admin/admin_add_category`,

  ShowWaiterApi: `${Main}/api/admin/admin_show_waiter`,
  AddWaiterApi: `${Main}/api/admin/admin_add_waiter`,
  DeleteWaiterApi: `${Main}/api/admin/admin_delete_waiter`,
  EditWaiterApi: `${Main}/api/admin/admin_edit_waiter`,

  OrderHistoryApi: `${Main}/api/admin/admin_order_history`,
  ViewBillApi: `${Main}/api/admin/admin_view_bill`,
  TodayEarningOrdersApi: `${Main}/api/admin/admin_today_earning`,
  UpdateOrderReqApi: `${Main}/api/admin/admin_edit_order`,

  UpdateOrderStatusApi: `${Main}/api/admin/admin_accept_order`,
  RequestOrderApi: `${Main}/api/admin/request_order`,
};
