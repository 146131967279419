import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import RequestOrder from "./components/RequestOrder";
import AddWaiter from "./components/AddWaiter";
import CreateMenu from "./components/CreateMenu";
import AddTable from "./components/AddTable";
import AcceptOrder from "./components/AcceptOrder";
import OrderInfo from "./components/OrderInfo";
import OrderHistory from "./components/OrderHistory";
import Scrolltotop from "./Scrolltotop";
import Signin from "./components/Signin";
import EmailVerification from "./components/EmailVerification";
import ForgotPassword from "./components/ForgotPassword";
import Successfullysentmail from "./components/Successfullysentmail";
import ChangePassword from "./components/ChangePassword";
import { Toaster } from "react-hot-toast";
import Counter from "./components/Counter";

function App() {
  return (
    <div className="App">
      <Router>
        <Scrolltotop />
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/EmailVerification" element={<EmailVerification />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/RequestOrder" element={<RequestOrder />} />
          <Route path="/AddWaiter" element={<AddWaiter />} />
          <Route path="/CreateMenu" element={<CreateMenu />} />
          <Route path="/AddTable" element={<AddTable />} />
          <Route path="/AcceptOrder" element={<AcceptOrder />} />
          <Route path="/OrderInfo" element={<OrderInfo />} />
          <Route path="/OrderHistory" element={<OrderHistory />} />
          <Route
            path="/Successfullysentmail"
            element={<Successfullysentmail />}
          />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/Counter" element={<Counter />} />
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
