import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import billlogo from "../Image/bill-logo.svg";
import {
  AddWaiterApi,
  DeleteWaiterApi,
  EditWaiterApi,
  GetWaitersApi,
} from "../helper/integration";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
const AddWaiter = () => {
  const [AllData, setAllData] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [SignleData, setSignleData] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [UserName, setUserName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Mobile, setMobile] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // State for search input

  // const [tableName, setTableName] = useState(""); // Add state for table name
  const [cookies] = useCookies(["restaurant_id"]);
  const restaurant_id = cookies.restaurant_id?.restaurant_id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedRestaurantId = cookies.restaurant_id;
    if (fetchedRestaurantId) {
      setRestaurantId(fetchedRestaurantId);
      console.log("Fetched restaurant_id:", fetchedRestaurantId);
      ///navigate("/Dashboard");
    } else {
      console.log("Restaurant ID not found in cookies.", cookies);
      // Handle the case where restaurant_id is not in the cookies
      navigate("/");
    }
  }, [cookies, navigate]);

  useEffect(() => {
    (async () => {
      if (!restaurant_id) return;
      try {
        setisLoad(false);
        const data = await GetWaitersApi(restaurant_id);
        console.log("Fetched Waitersdata:", data);
        setAllData(data); // Ensure data is set correctly
        setisLoad(true);
      } catch (error) {
        console.error("Error fetching waiters data:", error);
      }
    })();
  }, [isUpdate, restaurant_id]);

  useEffect(() => {
    // Filter the data based on the search input
    const filtered = AllData.filter((item) =>
      item.username.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchInput, AllData]);

  useEffect(() => {
    const modalElement = document.getElementById("AddWaiter");
    modalElement.addEventListener("show.bs.modal", () => {
      // Clear the state values when the modal is opened
      setUserName("");
      setEmail("");
      setPassword("");
      setMobile("");
    });

    return () => {
      modalElement.removeEventListener("show.bs.modal", () => {});
    };
  }, []);
  const handleAddWaiter = async (e) => {
    e.preventDefault();

    if (!UserName || !restaurant_id) {
      toast.error("Please enter table name");
      return;
    }

    // Create the promise for adding a table
    const addPromise = AddWaiterApi({
      username: UserName,
      email: Email,
      password: Password,
      mobile: Mobile,
      restaurant_id,
    });

    // Use toast.promise to handle different states of the promise
    toast.promise(
      addPromise,
      {
        loading: "Wait a few moments...",
        success: "Waiter added successfully!",
        error: "An error occurred while adding the Waiter.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000,
        },
        error: {
          duration: 5000,
        },
      }
    );
    try {
      // Await the promise to handle additional operations after toast
      const response = await addPromise;

      if (response.status === "success") {
        // Clear the input field and trigger data refresh
        setUserName("");
        setEmail("");
        setPassword("");
        setMobile("");
        setisUpdate(true);
      } else if (
        response.status === "error" &&
        response.message === "Waiter already exists in menu"
      ) {
        // Handle the specific case where the table already exists
        toast.error("Waiter already exists");
      } else {
        toast.error(response.message || "Failed to add Waiter");
      }
    } catch (error) {
      // The error toast from toast.promise will handle the error message
      console.log("Error adding Waiter:", error);
    }
  };

  const handleDeleteClick = (user_id) => {
    setUserIdToDelete(user_id); // Set the table ID for deletion
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show(); // Show the confirmation modal
  };

  const handleDeleteWaiter = async () => {
    if (!userIdToDelete) {
      toast.error("No table selected for deletion");
      return;
    }

    // Create a promise for the delete operation
    const deletePromise = DeleteWaiterApi(userIdToDelete);
    // Show toast messages for different states of the promise
    toast.promise(
      deletePromise,
      {
        loading: "Wait a few moments...",
        success: "Waiter deleted successfully!",
        error: "An error occurred while deleting the waiter.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000, // Success message will be shown for 3 seconds
        },
        error: {
          duration: 5000, // Error message will be shown for 5 seconds
        },
      }
    );

    try {
      // Await the promise to get the response data
      const data = await deletePromise;

      if (data && data.status === "success") {
        setUserIdToDelete(null); // Clear the selected table ID
        setisUpdate(true); // Trigger data refresh
      } else {
        toast.error("Failed to delete waiter");
      }
    } catch (error) {
      // The toast.promise will handle the error message, so this is optional
      console.log("Error deleting waiter:", error);
    }
  };

  const handleEditWaiter = async (e) => {
    e.preventDefault();

    if (!UserName || !restaurant_id || !SignleData) {
      toast.error("Please enter table name and select a table");
      return;
    }

    const updatePromise = EditWaiterApi({
      user_id: SignleData.user_id, // Use the selected table ID for updating
      username: UserName,
      email: Email,
      password: Password,
      mobile: Mobile,
      restaurant_id,
    });

    toast.promise(
      updatePromise,
      {
        loading: "Wait a few moments...",
        success: "Waiter updated successfully!",
        error: "An error occurred while updating the waiter.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000, // Success message will be shown for 3 seconds
        },
        error: {
          duration: 5000, // Error message will be shown for 5 seconds
        },
      }
    );

    try {
      const response = await updatePromise;

      if (response.status === "success") {
        // Update the AllData state with the updated table data
        setAllData((prevData) =>
          prevData.map((item) =>
            item.user_id === SignleData.user_id
              ? {
                  ...item,
                  username: UserName,
                  //   email: Email,
                  //   password: Password,
                  //   mobile: Mobile,
                }
              : item
          )
        );

        setUserName(""); // Clear the input field
        //setAllData(""); // Clear the input field
        setEmail(""); // Clear the input field
        setPassword(""); // Clear the input field
        setMobile(""); // Clear the input field
        setisUpdate(true); // Trigger data refresh
      } else {
        toast.error(response.message || "Failed to update waiter");
      }
    } catch (error) {
      console.log("Error updating waiter:", error);
      // The error message is already handled by toast.promise, so no need to show it again
    }
  };

  const handleEditClick = (item) => {
    setSignleData(item);
    setEmail(item.email);
    setPassword(item.password);
    setUserName(item.username); // Populate the modal with existing table data
    setMobile(item.mobile); // Populate the modal with existing table data
  };

  useEffect(() => {
    const addTableModal = document.getElementById("AddWaiter");
    const editTableModal = document.getElementById("editWaiter");
    const handleModalClose = () => window.location.reload();
    if (addTableModal) {
      addTableModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    if (editTableModal) {
      editTableModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    // Clean up event listeners on unmount
    return () => {
      if (addTableModal) {
        addTableModal.removeEventListener("hidden.bs.modal", handleModalClose);
      }
      if (editTableModal) {
        editTableModal.removeEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, []);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="">
            <div className="page-heading">
              <h3>Add Waiter</h3>
            </div>
            <div className="bg-box">
              <div className="row">
                <div className="col-12">
                  <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                    <p className="mb-0">All Waiters</p>
                    <Link
                      to={"/Dashboard"}
                      type="submit"
                      className="btn btn-login w-auto p-2"
                      data-bs-toggle="modal"
                      data-bs-target="#AddWaiter"
                    >
                      Add Waiter
                    </Link>
                  </div>
                </div>
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="d-flex align-items-end justify-content-between mb-3">
                    <div className="d-flex"></div>
                    <div className="form-floating small-floating">
                      <input
                        type="text"
                        className="form-control py-2 w-auto"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)} // Update search input
                      />
                      <label for="floatingInput">Search</label>
                    </div>
                  </div>
                  <div className="responsive-table">
                    <table className="table border-tbl">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Email Id</th>
                          <th>Password</th>
                          <th>Contact No</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData && filteredData.length > 0 ? (
                          filteredData.map((item, index) => (
                            <tr key={item.item_id}>
                              <td>{index + 1}</td>
                              <td>{item.username}</td>
                              <td>{item.email}</td>
                              <td>{item.password}</td>
                              <td>{item.mobile}</td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editWaiter"
                                    onClick={() => handleEditClick(item)}
                                  >
                                    <i className="fa-solid fa-edit text-success"></i>
                                  </Link>
                                  <Link
                                    type="button"
                                    onClick={() =>
                                      handleDeleteClick(item.user_id)
                                    }
                                  >
                                    <i className="fa-solid fa-trash text-danger"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No Waiter available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Waiter --> */}
      <div
        className="modal fade"
        id="AddWaiter"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Waiter
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row" onSubmit={handleAddWaiter}>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Enter Name</label>
                    <input
                      type="Username"
                      value={UserName}
                      onChange={(e) => setUserName(e.target.value)}
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Enter Email Id</label>
                    <input
                      type="email"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Enter Password</label>
                    <input
                      type="password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Enter Contact No</label>
                    <input
                      type="tel"
                      value={Mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      type="button"
                      className="btn btn-reset"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Add Waiter --> */}

      {/* <!-- Edit Waiter --> */}
      <div
        className="modal fade"
        id="editWaiter"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Waiter
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row" onSubmit={handleEditWaiter}>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Name</label>
                    <input
                      type="username"
                      value={UserName}
                      onChange={(e) => setUserName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Email Id</label>
                    <input
                      type="email"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Password</label>
                    <input
                      type="password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Contact No</label>
                    <input
                      type="mobile"
                      value={Mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      className="btn btn-reset"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Edit Waiter --> */}
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
        //style={{ visibility: "visible" }} // For debugging
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 text-center">
                Are you sure you want to delete this Waiter ?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  className="btn btn-reset me-2"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteWaiter}
                >
                  Yes
                </button>
                <button className="btn btn-upload" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWaiter;
