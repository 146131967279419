import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import billlogo from "../Image/bill-logo.svg";
import toast from "react-hot-toast";
import {
  GetCategoryMenuApi,
  GetCategoryMenu2Api,
  RemoveCategoryApi,
  UpdateCategoryApi,
  AddCategoryApi,
  ShowItemApi,
  DeleteItemsApi,
  AddItemsApi,
  EditItemsApi,
} from "../helper/integration";
//import UpdateModel from "../common/CreateMenu/Edit_Menu";
import { useCookies } from "react-cookie"; // Import useCookies from react-cookie
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const CreateMenu = ({ category, item }) => {
  const [AllData, setAllData] = useState([]);
  const [AllitemData, setAllItemData] = useState([]);
  const [AllCatFilterData, setAllCatFilterData] = useState([]);
  const [selectedCategory2, setSelectedCategory2] = useState(null);
  const [AllCatData, setAllCatData] = useState([]);
  const [restaurant_id, setRestaurantId] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [SignleData, setSignleData] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");

  const [foodType, setFoodType] = useState("");
  const [previewImage, setPreviewImage] = useState(null);

  const [image, setImage] = useState("");
  const [filteredData2, setFilteredData2] = useState([]);
  const [searchInput2, setSearchInput2] = useState("");
  const [loading, setLoading] = useState(false);
  // const [item_id, setItemId] = useState("");
  const [itemId, setItemId] = useState("");

  const [itemData, setItemData] = useState(null);

  const [cookies] = useCookies(["restaurant_id"]);

  const [formdatanameitemprice, setformitem_price] = useState("");

  const [show_formitem_item_name, setformitem_item_name] = useState("");
  const [show_formitem_item_id, setformitem_item_id] = useState("");
  const [show_formitem_type, setformitem_type] = useState("");
  const [show_formitem_restaurant_id, setformitem_restaurant_id] = useState("");
  const [show_formitem_category_id, setformitem_category_id] = useState("");
  const [show_formitem_category_name, setformitem_category_name] = useState("");

  useEffect(() => {
    const fetchedRestaurantId = cookies.restaurant_id;
    if (fetchedRestaurantId) {
      setRestaurantId(fetchedRestaurantId);
      console.log("Fetched restaurant_id:", fetchedRestaurantId);
    } else {
      console.log("Restaurant ID not found in cookies.", cookies);
      // Handle the case where restaurant_id is not in the cookies
    }
  }, [cookies]);

  // Fetch category data when restaurant_id or isUpdate changes
  useEffect(() => {
    const fetchCategories = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          const data = await GetCategoryMenuApi(adminCookie.restaurant_id);
          setAllData(data);
        } catch (error) {
          console.error("Failed to fetch categories:", error);
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    fetchCategories();
  }, [cookies]);

  useEffect(() => {
    // Filter the data based on the search input
    const filtered = AllData.filter((item) =>
      item.category_name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchInput, AllData]);

  //const handleSetSingleData = (item) => setSignleData(item);

  useEffect(() => {
    if (category && item) {
      setSelectedCategory(category.category_id);
      setItemName(item.name);
      setItemPrice(item.price);
      setItemId(item.id);
      setFoodType(item.foodType);
      // Assuming you have the image URL
      setImage(item.image);
    }
  }, [category, item]);

  const handleSetSingleData = (category) => {
    setSelectedCategory(category);
    setCategoryName(category.category_name);
    // Show the modal (assuming you're using Bootstrap)
    const modal = new window.bootstrap.Modal(
      document.getElementById("editCategorys")
    );
    modal.show();
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();

    try {
      if (selectedCategory?.category_id) {
        await toast.promise(
          UpdateCategoryApi(selectedCategory.category_id, categoryName),
          {
            loading: "Wait a few moments...",
            success: "Category updated successfully!",
            error: "Something went wrong!",
          }
        );
        // Update the AllData state to reflect changes
        setAllData((prevData) =>
          prevData.map((category) =>
            category.category_id === selectedCategory.category_id
              ? { ...category, category_name: categoryName }
              : category
          )
        );
        setisUpdate((prev) => !prev);
        setSelectedCategory(null);
        setCategoryName("");
        const modal = window.bootstrap.Modal.getInstance(
          document.getElementById("editCategorys")
        );
        if (modal) modal.hide();
      } else {
        console.error("No category_id available for update.");
      }
    } catch (error) {
      console.log("Error updating category:", error);
    }
  };

  const handleDeleteClick = (category_id) => {
    setUserIdToDelete(category_id); // Set the category ID for deletion
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show(); // Show the confirmation modal
  };

  const handleDeleteCategory = async () => {
    if (!userIdToDelete) {
      toast.error("No category selected for deletion");
      return;
    }

    try {
      // Call the API to delete the category
      await toast.promise(
        RemoveCategoryApi(userIdToDelete),
        {
          loading: "Wait a few moments...",
          success: "Category deleted successfully!",
          error: "An error occurred while deleting the category.",
        },
        {
          loading: {
            duration: 3000,
          },
          success: {
            duration: 3000, // Success message will be shown for 3 seconds
          },
          error: {
            duration: 5000, // Error message will be shown for 5 seconds
          },
        }
      );
      setisUpdate();
      // Remove the deleted category from the AllData state
      setAllData((prevData) =>
        prevData.filter((category) => category.category_id !== userIdToDelete)
      );

      // Reset states
      setUserIdToDelete(null);
    } catch (error) {
      console.log("Error deleting category:", error);
    } finally {
      // Close the modal
      const modal = window.bootstrap.Modal.getInstance(
        document.getElementById("deleteConfirmationModal")
      );
      if (modal) modal.hide();
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    const { restaurant_id } = cookies.restaurant_id || {};

    // Check if both categoryName and restaurant_id are present
    if (!categoryName || !restaurant_id) {
      toast.error("Please enter category name and select a restaurant.");
      return;
    }

    console.log("Restaurant ID:", restaurant_id);
    console.log("Category Name:", categoryName);
    // Create the promise for adding a table
    const addPromise = AddCategoryApi({
      category_name: categoryName,
      restaurant_id,
    });
    console.log(categoryName);

    // Use toast.promise to handle different states of the promise
    toast.promise(
      addPromise,
      {
        loading: "Wait a few moments...",
        success: "Category added successfully!",
        error: "An error occurred while adding the category.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000,
        },
        error: {
          duration: 5000,
        },
      }
    );
    //setisUpdate();
    try {
      // Await the promise to handle additional operations after toast
      const response = await addPromise;
      console.log(response);
      if (response.status === "success") {
        // Clear the input field and trigger data refresh
        setCategoryName("");
        setisUpdate();
        setisLoad(true);
        //fetchCategories();
      } else if (
        response.status === "error" &&
        response.message === "Category already exists in menu"
      ) {
        // Handle the specific case where the table already exists
        toast.error("Category already exists");
      } else {
        toast.error(response.message || "Failed to add table");
      }
    } catch (error) {
      // The error toast from toast.promise will handle the error message
      console.log("Error adding table:", error);
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          setisLoad(false); // Start loading
          const data = await ShowItemApi(adminCookie.restaurant_id);
          console.log("Fetched items data:", data);
          setAllItemData(data); // Set data in state
        } catch (error) {
          console.error("Error fetching items data:", error);
        } finally {
          setisLoad(true); // End loading
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    fetchItems();
  }, [isUpdate, cookies]); // Dependencies: isUpdate and cookies

  // Filter the data based on the search input
  useEffect(() => {
    const filtered = AllitemData.filter((item) =>
      item.item_name.toLowerCase().includes(searchInput2.toLowerCase())
    );
    setFilteredData2(filtered);
  }, [searchInput2, AllitemData]);

  // Filter the data based on the selected category
  useEffect(() => {
    if (selectedCategory2) {
      const filteredItems = AllitemData.filter(
        (item) => item.category_id === selectedCategory2
      );
      setFilteredData2(filteredItems);
    } else {
      setFilteredData2(AllitemData); // Show all items if no category is selected
    }
  }, [selectedCategory2, AllitemData]);

  useEffect(() => {
    const fetchCategories = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          const data = await GetCategoryMenuApi(adminCookie.restaurant_id);
          setAllCatFilterData(data);
        } catch (error) {
          console.error("Failed to fetch categories:", error);
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    fetchCategories();
  }, [cookies]);

  const handleDeleteClick2 = (item_id) => {
    setUserIdToDelete(item_id); // Set the category ID for deletion
    const deleteModal2 = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal2")
    );
    deleteModal2.show(); // Show the confirmation modal
  };

  const handleDeleteItem = async () => {
    if (!userIdToDelete) {
      toast.error("No category selected for deletion");
      return;
    }

    try {
      // Call the API to delete the category
      await toast.promise(
        DeleteItemsApi(userIdToDelete),
        {
          loading: "Wait a few moments...",
          success: "Item deleted successfully!",
          error: "An error occurred while deleting the item.",
        },
        {
          loading: {
            duration: 3000,
          },
          success: {
            duration: 3000, // Success message will be shown for 3 seconds
          },
          error: {
            duration: 5000, // Error message will be shown for 5 seconds
          },
        }
      );
      setisUpdate();
      // Remove the deleted category from the AllData state
      setAllData((prevData) =>
        prevData.filter((item) => item.item_id !== userIdToDelete)
      );

      // Reset states
      setUserIdToDelete(null);
    } catch (error) {
      console.log("Error deleting item:", error);
    } finally {
      // Close the modal
      const modal = window.bootstrap.Modal.getInstance(
        document.getElementById("deleteConfirmationModal2")
      );
      if (modal) modal.hide();
    }
  };

  // const handleSetSingleData2 = async (item_id) => {
  //   try {
  //     console.log(item_id);

  //     const restaurant_id = cookies.restaurant_id; // Fetch restaurant_id from cookies

  //     if (!restaurant_id) {
  //       console.error("No restaurant_id in cookies.");
  //       return;
  //     }

  //     // Fetch item details by passing both item_id and restaurant_id
  //     const itemsData = await ShowItemApi(restaurant_id.restaurant_id, item_id);
  //     const item = itemsData[0]; // Access the first element from the response

  //     if (!item) {
  //       console.error("No item data found.");
  //       return;
  //     }

  //     // Set fetched item data to the state to populate the form
  //     setItemData(item);

  //     setformitem_item_name(item.item_name);
  //     setformitem_item_id(item.item_id);
  //     setformitem_type(item.type);
  //     setformitem_price(item.price);
  //     setformitem_restaurant_id(item.restaurant_id);
  //     setformitem_category_id(item.category_id);
  //     setformitem_category_name(item.category_name);

  //     useEffect(() => {
  //       if (category && item) {
  //         //setSelectedCategory(category.category_id);
  //         setItemName(item.name);
  //         setItemPrice(item.price);
  //         setItemId(item.id);
  //         setFoodType(item.foodType);
  //         // Assuming you have the image URL
  //         setImage(item.image);
  //       }
  //     }, [category, item]);

  //     console.log("Log Active itemPrice", item.price);

  //     // Fetch all categories using GetCategoryMenuApi
  //     // const allCategories = await GetCategoryMenuApi(restaurant_id); // Pass restaurant_id to GetCategoryMenuApi
  //     // setAllCatData(allCategories);

  //     // // Find the category by item.category_id from the fetched categories
  //     // const selectedCategory = allCategories.find(
  //     //   (category) => category.category_id === item.category_id
  //     // );

  //     // if (selectedCategory) {
  //     //   setCategoryName(selectedCategory.category_name); // Set the category name
  //     //   setCategoryId(selectedCategory.category_id); // Store the category ID
  //     // }

  //     // Set item details from the fetched itemData
  //     setItemName(item.item_name || ""); // Set item name
  //     setItemPrice(item.price || ""); // Set item price
  //     setFoodType(item.type || ""); // Set food type
  //     setItemId(item.item_id || ""); // Set item ID
  //     setImage(item.image || ""); // Set item image from the API

  //     setisUpdate(true);

  //     // Show the modal (assuming you're using Bootstrap)
  //     const modal = new window.bootstrap.Modal(
  //       document.getElementById("editItems")
  //     );
  //     modal.show();
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(
  //         "Error fetching item or category data:",
  //         error.response.data
  //       );
  //     } else {
  //       console.log("Error fetching item or category data:", error);
  //     }
  //   }
  // };

  const handleEditClick = async (item) => {
    // Assuming 'item' is an object that contains all item details, including item_id
    setItemData(item);
    setItemId(item.item_id); // Set the item_id in state
    setformitem_item_name(item.item_name); // Set item name in state
    //setformitem_category_id(item.category_id); // Set item name in state
    setformitem_price(item.price); // Set price in state
    setformitem_type(item.type); // Set food type in state
    setImage(item.image); // If image is part of item data
    setSelectedCategory(item.category_id);
    // const allCategories = await GetCategoryMenuApi(restaurant_id); // Pass restaurant_id to GetCategoryMenuApi
    // setAllCatData(allCategories);

    // // Find the category by item.category_id from the fetched categories
    // const selectedCategory = allCategories.find(
    //   (category) => category.category_id === item.category_id
    // );

    // if (selectedCategory) {
    //   setCategoryName(selectedCategory.category_name); // Set the category name
    //   setSelectedCategory(selectedCategory.category_id); // Store the category ID
    // }
  };
  const handleEditItem = async (e) => {
    e.preventDefault();

    // if (!show_formitem_item_name || !formdatanameitemprice) {
    //   console.log("Missing required fields");
    //   return;
    // }

    const formData = new FormData();
    if (selectedCategory) {
      formData.append("category_id", selectedCategory);
    }

    //formData.append("category_id", selectedCategory);
    formData.append("item_name", show_formitem_item_name);
    formData.append("price", formdatanameitemprice);
    formData.append("type", show_formitem_type);
    if (image) {
      formData.append("image", image);
    }

    try {
      await toast.promise(EditItemsApi(itemId, formData), {
        loading: "Wait a few moments...",
        success: "Item updated successfully!",
        error: "Failed to update item.",
      });
      setisUpdate();

      const modalElement = document.getElementById("editItems");
      if (modalElement) {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
      }
    } catch (error) {
      console.error("Error editing item:", error);
      console.log("Error editing item:", error);
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    const { restaurant_id } = cookies.restaurant_id || {};
    if (!itemName || !itemPrice || !categoryId || !foodType) {
      console.log("Missing required fields");
      return;
    }

    const formData = new FormData();
    formData.append("category_id", categoryId);
    formData.append("item_name", itemName);
    formData.append("price", itemPrice);
    formData.append("type", foodType);
    formData.append("restaurant_id", restaurant_id); // Add restaurant_id to form data

    if (image) {
      formData.append("image", image);
    }

    try {
      await toast.promise(AddItemsApi(formData), {
        loading: "Adding item...",
        success: "Item added successfully!",
        error: "Failed to add item.",
      });
      setisUpdate();

      const modal = window.bootstrap.Modal.getInstance(
        document.getElementById("AddItems")
      );
      if (modal) modal.hide();
    } catch (error) {
      console.log("Error adding item:", error);
    }
  };

  useEffect(() => {
    const addCategoryModal = document.getElementById("AddCategorys");
    const editCategoryModal = document.getElementById("editCategorys");
    const addTableModal = document.getElementById("AddItems");
    const editTableModal = document.getElementById("editItems");
    const handleModalClose = () => window.location.reload();
    if (editCategoryModal) {
      editCategoryModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    if (addCategoryModal) {
      addCategoryModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    if (addTableModal) {
      addTableModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    if (editTableModal) {
      editTableModal.addEventListener("hidden.bs.modal", handleModalClose);
    }

    return () => {
      if (editCategoryModal) {
        editCategoryModal.removeEventListener(
          "hidden.bs.modal",
          handleModalClose
        );
      }
      if (editTableModal) {
        editTableModal.removeEventListener("hidden.bs.modal", handleModalClose);
      }
      if (addCategoryModal) {
        addCategoryModal.removeEventListener(
          "hidden.bs.modal",
          handleModalClose
        );
      }
    };
  }, []);

  // Function to open the modal with the selected image
  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setPreviewImage(null);
  };

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="">
            <div className="page-heading">
              <h3>Create Menu</h3>
            </div>
            <div className="bg-box">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item ms-0" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    {" "}
                    Items{" "}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    {" "}
                    Categorys{" "}
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                        <p className="mb-0">All Items</p>
                        <Link
                          to={"/Dashboard"}
                          type="submit"
                          className="btn btn-login w-auto p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#AddItems"
                        >
                          Add Items
                        </Link>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="d-flex align-items-end justify-content-between mb-3">
                        <div className="d-flex">
                          <div className=" me-3">
                            <label className="mb-2">Select Category</label>
                            <select
                              className="form-select form-control"
                              id=""
                              aria-label="Floating label select example"
                              value={selectedCategory2}
                              onChange={(e) =>
                                setSelectedCategory2(e.target.value)
                              }
                            >
                              <option value="" selected>
                                Select All category
                              </option>
                              {AllCatFilterData.map((category) => (
                                <option
                                  key={category.category_id}
                                  value={category.category_id}
                                >
                                  {category.category_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-floating small-floating">
                          <input
                            type="text"
                            className="form-control py-2 w-auto"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={searchInput2}
                            onChange={(e) => setSearchInput2(e.target.value)}
                          />
                          <label for="floatingInput">Search</label>
                        </div>
                      </div>
                      <div className="responsive-table">
                        <table className="table border-tbl">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Category</th>
                              <th>Item Name</th>
                              <th>Price</th>
                              <th>Food Type</th>
                              <th>Image</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData2 && filteredData2.length > 0 ? (
                              filteredData2.map((item, index) => (
                                <tr key={item.item_id}>
                                  <td>{index + 1}</td>
                                  <td>{item.category_name}</td>
                                  <td>{item.item_name}</td>
                                  <td>{item.price}</td>
                                  <td>{item.type}</td>
                                  <td>
                                    <div className="item-img">
                                      <img
                                        src={item.image}
                                        className="img-fluid"
                                        alt={item.item_name}
                                        style={{
                                          //width: "50px",
                                          cursor: "pointer",
                                        }} // Small size, clickable
                                        onClick={() =>
                                          handleImageClick(item.image)
                                        } // Click event to open the modal
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="icon-up-del">
                                      <Link
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editItems"
                                        onClick={() => handleEditClick(item)}
                                      >
                                        <i className="fa-solid fa-edit text-success"></i>
                                      </Link>

                                      <Link
                                        type="button"
                                        onClick={() =>
                                          handleDeleteClick2(item.item_id)
                                        }
                                      >
                                        <i className="fa-solid fa-trash text-danger"></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No items available</td>
                              </tr>
                            )}
                            {/* <tr>
                              <td>2</td>
                              <td>Sweets</td>
                              <td>Jalebi & Fafda</td>
                              <td>100</td>
                              <td>Non-Veg</td>
                              <td>
                                <div className="item-img">
                                  <img src={user} className="img-fluid" />
                                </div>
                              </td>
                              <td>
                                <div className="icon-up-del">
                                  <Link
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editItems"
                                  >
                                    <i className="fa-solid fa-edit text-success"></i>
                                  </Link>
                                  <Link>
                                    <i className="fa-solid fa-trash text-danger"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                      {previewImage && (
                        <div className="modal show d-block" role="dialog">
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Image Preview</h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={handleCloseModal}
                                ></button>
                              </div>
                              <div className="modal-body text-center">
                                <img
                                  src={previewImage}
                                  alt="Preview"
                                  className="img-fluid"
                                  style={{ maxWidth: "100%" }}
                                />
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={handleCloseModal}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                        <p className="mb-0">All Categorys</p>
                        <Link
                          to={"/Dashboard"}
                          type="submit"
                          className="btn btn-login w-auto p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#AddCategorys"
                        >
                          Add Categorys
                        </Link>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="d-flex align-items-end justify-content-between mb-3">
                        <div className="d-flex"></div>
                        <div className="form-floating small-floating">
                          <input
                            type="text"
                            className="form-control py-2 w-auto"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          <label for="floatingInput">Search</label>
                        </div>
                      </div>
                      <div className="responsive-table">
                        <table className="table border-tbl">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Category Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData && filteredData.length > 0 ? (
                              filteredData.map((category, index) => (
                                <tr key={category.category_id}>
                                  <td>{index + 1}</td>
                                  <td>{category.category_name}</td>
                                  <td>
                                    <div className="icon-up-del">
                                      <Link
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editCategorys"
                                        onClick={() =>
                                          handleSetSingleData(category)
                                        }
                                      >
                                        <i className="fa-solid fa-edit text-success"></i>
                                      </Link>
                                      <Link
                                        type="button"
                                        onClick={() =>
                                          handleDeleteClick(
                                            category.category_id
                                          )
                                        }
                                      >
                                        <i className="fa-solid fa-trash text-danger"></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No categories available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Items --> */}
      <div
        className="modal fade"
        id="AddItems"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Item
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row" onSubmit={handleAddItem}>
                <div className="mb-3 col-12">
                  <label className="mb-2">Select Category</label>
                  <select
                    className="form-select form-control"
                    value={categoryId}
                    onChange={(e) => setCategoryId(parseInt(e.target.value))}
                    aria-label="Select category"
                  >
                    <option value="" selected disabled>
                      Select category
                    </option>
                    {AllData.map((category) => (
                      <option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Item Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                  />
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Price</label>
                  <input
                    type="text"
                    className="form-control"
                    value={itemPrice}
                    onChange={(e) => setItemPrice(e.target.value)}
                  />
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Food Type</label>
                  <select
                    className="form-select form-control"
                    value={foodType}
                    onChange={(e) => setFoodType(e.target.value)}
                    aria-label="Food type"
                  >
                    <option value="" disabled>
                      Select Food Type
                    </option>
                    <option value="veg">Veg</option>
                    <option value="non-veg">Non-Veg</option>
                  </select>
                </div>
                <div className="mb-3 col-12">
                  <label className="mb-2">Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                  {image && (
                    <div className="mt-2">
                      6554
                      <img
                        src={
                          typeof image === "string"
                            ? image
                            : URL.createObjectURL(image)
                        }
                        alt="Selected Item"
                        style={{ width: "100px" }}
                      />
                    </div>
                  )}
                </div>
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    type="button"
                    className="btn btn-reset"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-upload me-0">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Add Items --> */}

      {/* Edit Items Modal */}
      {/* <!-- Edit Items --> */}
      <div
        className="modal fade"
        id="editItems"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Item
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row" onSubmit={handleEditItem}>
                {/* Category */}
                <div className="mb-3 col-12">
                  <label className="mb-2">Select Category</label>
                  <select
                    className="form-select"
                    value={selectedCategory || ""}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">Select category</option>
                    {AllData.map((category) => (
                      <option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Item Name */}
                <div className="mb-3 col-12">
                  <label className="mb-2">Item Name</label>
                  <input
                    type="text"
                    value={show_formitem_item_name}
                    onChange={(e) => setformitem_item_name(e.target.value)}
                    className="form-control"
                  />
                </div>

                {/* Price */}
                <div className="mb-3 col-12">
                  <label className="mb-2">Price</label>
                  <input
                    type="text"
                    value={formdatanameitemprice}
                    onChange={(e) => setformitem_price(e.target.value)}
                    className="form-control"
                  />
                </div>

                {/* Item ID */}
                {/* <div className="mb-3 col-12">
                  <label className="mb-2">Item ID</label>
                  <input
                    type="text"
                    value={show_formitem_item_id}
                    className="form-control"
                    readOnly
                  />
                </div> */}

                {/* Food Type */}
                <div className="mb-3 col-12">
                  <label className="mb-2">Select Food Type</label>
                  <select
                    className="form-select"
                    value={show_formitem_type}
                    onChange={(e) => setformitem_type(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Food Type
                    </option>
                    <option value="veg">Veg</option>
                    <option value="non-veg">Non-Veg</option>
                  </select>
                </div>

                {/* Image Upload */}
                <div className="mb-3 col-12">
                  <label className="mb-2">Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                  {image && (
                    <div className="mt-2">
                      <img
                        src={
                          typeof image === "string"
                            ? image
                            : URL.createObjectURL(image)
                        }
                        alt="Selected Item"
                        style={{ width: "100px" }}
                      />
                    </div>
                  )}
                </div>

                {/* Buttons */}
                <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                  <button
                    className="btn btn-reset"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-upload me-0"
                    type="submit"
                    data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Edit Items --> */}

      {/* <!-- Add Categorys --> */}
      <div
        className="modal fade"
        id="AddCategorys"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row" onSubmit={handleAddCategory}>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Category Name</label>
                    <input
                      type="text"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      className="btn btn-reset"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Add Categorys --> */}

      {/* <!-- Edit Categorys --> */}
      <div
        className="modal fade"
        id="editCategorys"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row" onSubmit={handleUpdateCategory}>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Category Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      className="btn btn-reset"
                      id="closeUpdateCategory"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit" // Ensure this is a submit button
                      className="btn btn-upload me-0"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <UpdateModel
        item={SignleData}
        setItem={setSignleData}
        isUpdate={isUpdate}
        setisUpdate={setisUpdate}
      /> */}
      {/* <!--End Edit Categorys --> */}
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
        //style={{ visibility: "visible" }} // For debugging
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 text-center">
                Are you sure you want to delete this Category ?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  className="btn btn-reset me-2"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteCategory}
                >
                  Yes
                </button>
                <button className="btn btn-upload" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteConfirmationModal2"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
        //style={{ visibility: "visible" }} // For debugging
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel2">
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 text-center">
                Are you sure you want to delete this Item ?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  className="btn btn-reset me-2"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteItem}
                >
                  Yes
                </button>
                <button className="btn btn-upload" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMenu;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import user from "../Image/user.png";
// import billlogo from "../Image/bill-logo.svg";
// import toast from "react-hot-toast";
// import {
//   GetCategoryMenuApi,
//   RemoveCategoryApi,
//   UpdateCategoryApi,
//   AddCategoryApi,
//   ShowItemsApi,
//   DeleteItemsApi,
//   AddItemsApi,
//   EditItemsApi,
// } from "../helper/integration";
// //import UpdateModel from "../common/CreateMenu/Edit_Menu";
// import { useCookies } from "react-cookie"; // Import useCookies from react-cookie
// import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

// const CreateMenu = ({ category, item }) => {
//   const [AllData, setAllData] = useState([]);
//   const [AllitemData, setAllItemData] = useState([]);
//   const [AllCatFilterData, setAllCatFilterData] = useState([]);
//   const [selectedCategory2, setSelectedCategory2] = useState(null);
//   const [AllCatData, setAllCatData] = useState([]);
//   const [restaurant_id, setRestaurantId] = useState(null);
//   const [isUpdate, setisUpdate] = useState(false);
//   const [isLoad, setisLoad] = useState(false);
//   const [SignleData, setSignleData] = useState(null);
//   const [userIdToDelete, setUserIdToDelete] = useState(null);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [categoryName, setCategoryName] = useState("");
//   const [categoryId, setCategoryId] = useState(null);
//   const [filteredData, setFilteredData] = useState([]);
//   const [searchInput, setSearchInput] = useState("");
//   const [itemName, setItemName] = useState("");
//   const [itemPrice, setItemPrice] = useState("");
//   const [foodType, setFoodType] = useState("");
//   const [previewImage, setPreviewImage] = useState(null);

//   const [image, setImage] = useState("");
//   const [filteredData2, setFilteredData2] = useState([]);
//   const [searchInput2, setSearchInput2] = useState("");
//   const [loading, setLoading] = useState(false);
//   // const [item_id, setItemId] = useState("");
//   const [itemId, setItemId] = useState("");

//   const [itemData, setItemData] = useState(null);

//   const [cookies] = useCookies(["restaurant_id"]);

//   useEffect(() => {
//     const fetchedRestaurantId = cookies.restaurant_id;
//     if (fetchedRestaurantId) {
//       setRestaurantId(fetchedRestaurantId);
//       console.log("Fetched restaurant_id:", fetchedRestaurantId);
//     } else {
//       console.log("Restaurant ID not found in cookies.", cookies);
//       // Handle the case where restaurant_id is not in the cookies
//     }
//   }, [cookies]);

//   // Fetch category data when restaurant_id or isUpdate changes
//   useEffect(() => {
//     const fetchCategories = async () => {
//       const adminCookie = cookies.restaurant_id;

//       if (adminCookie && adminCookie.restaurant_id) {
//         try {
//           const data = await GetCategoryMenuApi(adminCookie.restaurant_id);
//           setAllData(data);
//         } catch (error) {
//           console.error("Failed to fetch categories:", error);
//         }
//       } else {
//         console.error("No restaurant_id in cookies.");
//       }
//     };

//     fetchCategories();
//   }, [cookies]);

//   useEffect(() => {
//     // Filter the data based on the search input
//     const filtered = AllData.filter((item) =>
//       item.category_name.toLowerCase().includes(searchInput.toLowerCase())
//     );
//     setFilteredData(filtered);
//   }, [searchInput, AllData]);

//   //const handleSetSingleData = (item) => setSignleData(item);

//   useEffect(() => {
//     if (category && item) {
//       setSelectedCategory(category.category_id);
//       setItemName(item.name);
//       setItemPrice(item.price);
//       setItemId(item.id);
//       setFoodType(item.foodType);
//       // Assuming you have the image URL
//       setImage(item.image);
//     }
//   }, [category, item]);

//   const handleSetSingleData = (category) => {
//     setSelectedCategory(category);
//     setCategoryName(category.category_name);
//     // Show the modal (assuming you're using Bootstrap)
//     const modal = new window.bootstrap.Modal(
//       document.getElementById("editCategorys")
//     );
//     modal.show();
//   };

//   const handleUpdateCategory = async (e) => {
//     e.preventDefault();

//     try {
//       if (selectedCategory?.category_id) {
//         await toast.promise(
//           UpdateCategoryApi(selectedCategory.category_id, categoryName),
//           {
//             loading: "Wait a few moments...",
//             success: "Category updated successfully!",
//             error: "Something went wrong!",
//           }
//         );
//         // Update the AllData state to reflect changes
//         setAllData((prevData) =>
//           prevData.map((category) =>
//             category.category_id === selectedCategory.category_id
//               ? { ...category, category_name: categoryName }
//               : category
//           )
//         );
//         setisUpdate((prev) => !prev);
//         setSelectedCategory(null);
//         setCategoryName("");
//         const modal = window.bootstrap.Modal.getInstance(
//           document.getElementById("editCategorys")
//         );
//         if (modal) modal.hide();
//       } else {
//         console.error("No category_id available for update.");
//       }
//     } catch (error) {
//       console.log("Error updating category:", error);
//     }
//   };

//   const handleDeleteClick = (category_id) => {
//     setUserIdToDelete(category_id); // Set the category ID for deletion
//     const deleteModal = new bootstrap.Modal(
//       document.getElementById("deleteConfirmationModal")
//     );
//     deleteModal.show(); // Show the confirmation modal
//   };

//   const handleDeleteCategory = async () => {
//     if (!userIdToDelete) {
//       toast.error("No category selected for deletion");
//       return;
//     }

//     try {
//       // Call the API to delete the category
//       await toast.promise(
//         RemoveCategoryApi(userIdToDelete),
//         {
//           loading: "Wait a few moments...",
//           success: "Category deleted successfully!",
//           error: "An error occurred while deleting the category.",
//         },
//         {
//           loading: {
//             duration: 3000,
//           },
//           success: {
//             duration: 3000, // Success message will be shown for 3 seconds
//           },
//           error: {
//             duration: 5000, // Error message will be shown for 5 seconds
//           },
//         }
//       );
//       setisUpdate();
//       // Remove the deleted category from the AllData state
//       setAllData((prevData) =>
//         prevData.filter((category) => category.category_id !== userIdToDelete)
//       );

//       // Reset states
//       setUserIdToDelete(null);
//     } catch (error) {
//       console.log("Error deleting category:", error);
//     } finally {
//       // Close the modal
//       const modal = window.bootstrap.Modal.getInstance(
//         document.getElementById("deleteConfirmationModal")
//       );
//       if (modal) modal.hide();
//     }
//   };

//   const handleAddCategory = async (e) => {
//     e.preventDefault();
//     const { restaurant_id } = cookies.restaurant_id || {};

//     // Check if both categoryName and restaurant_id are present
//     if (!categoryName || !restaurant_id) {
//       toast.error("Please enter category name and select a restaurant.");
//       return;
//     }

//     console.log("Restaurant ID:", restaurant_id);
//     console.log("Category Name:", categoryName);
//     // Create the promise for adding a table
//     const addPromise = AddCategoryApi({
//       category_name: categoryName,
//       restaurant_id,
//     });
//     console.log(categoryName);

//     // Use toast.promise to handle different states of the promise
//     toast.promise(
//       addPromise,
//       {
//         loading: "Wait a few moments...",
//         success: "Category added successfully!",
//         error: "An error occurred while adding the category.",
//       },
//       {
//         loading: {
//           duration: 3000,
//         },
//         success: {
//           duration: 3000,
//         },
//         error: {
//           duration: 5000,
//         },
//       }
//     );
//     //setisUpdate();
//     try {
//       // Await the promise to handle additional operations after toast
//       const response = await addPromise;
//       console.log(response);
//       if (response.status === "success") {
//         // Clear the input field and trigger data refresh
//         setCategoryName("");
//         setisUpdate();
//         setisLoad(true);
//         //fetchCategories();
//       } else if (
//         response.status === "error" &&
//         response.message === "Category already exists in menu"
//       ) {
//         // Handle the specific case where the table already exists
//         toast.error("Category already exists");
//       } else {
//         toast.error(response.message || "Failed to add table");
//       }
//     } catch (error) {
//       // The error toast from toast.promise will handle the error message
//       console.log("Error adding table:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchItems = async () => {
//       const adminCookie = cookies.restaurant_id;

//       if (adminCookie && adminCookie.restaurant_id) {
//         try {
//           setisLoad(false); // Start loading
//           const data = await ShowItemsApi(adminCookie.restaurant_id);
//           console.log("Fetched items data:", data);
//           setAllItemData(data); // Set data in state
//         } catch (error) {
//           console.error("Error fetching items data:", error);
//         } finally {
//           setisLoad(true); // End loading
//         }
//       } else {
//         console.error("No restaurant_id in cookies.");
//       }
//     };

//     fetchItems();
//   }, [isUpdate, cookies]); // Dependencies: isUpdate and cookies

//   // Filter the data based on the search input
//   useEffect(() => {
//     const filtered = AllitemData.filter((item) =>
//       item.item_name.toLowerCase().includes(searchInput2.toLowerCase())
//     );
//     setFilteredData2(filtered);
//   }, [searchInput2, AllitemData]);

//   // Filter the data based on the selected category
//   useEffect(() => {
//     if (selectedCategory2) {
//       const filteredItems = AllitemData.filter(
//         (item) => item.category_id === selectedCategory2
//       );
//       setFilteredData2(filteredItems);
//     } else {
//       setFilteredData2(AllitemData); // Show all items if no category is selected
//     }
//   }, [selectedCategory2, AllitemData]);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       const adminCookie = cookies.restaurant_id;

//       if (adminCookie && adminCookie.restaurant_id) {
//         try {
//           const data = await GetCategoryMenuApi(adminCookie.restaurant_id);
//           setAllCatFilterData(data);
//         } catch (error) {
//           console.error("Failed to fetch categories:", error);
//         }
//       } else {
//         console.error("No restaurant_id in cookies.");
//       }
//     };

//     fetchCategories();
//   }, [cookies]);

//   const handleDeleteClick2 = (item_id) => {
//     setUserIdToDelete(item_id); // Set the category ID for deletion
//     const deleteModal2 = new bootstrap.Modal(
//       document.getElementById("deleteConfirmationModal2")
//     );
//     deleteModal2.show(); // Show the confirmation modal
//   };

//   const handleDeleteItem = async () => {
//     if (!userIdToDelete) {
//       toast.error("No category selected for deletion");
//       return;
//     }

//     try {
//       // Call the API to delete the category
//       await toast.promise(
//         DeleteItemsApi(userIdToDelete),
//         {
//           loading: "Wait a few moments...",
//           success: "Item deleted successfully!",
//           error: "An error occurred while deleting the item.",
//         },
//         {
//           loading: {
//             duration: 3000,
//           },
//           success: {
//             duration: 3000, // Success message will be shown for 3 seconds
//           },
//           error: {
//             duration: 5000, // Error message will be shown for 5 seconds
//           },
//         }
//       );
//       setisUpdate();
//       // Remove the deleted category from the AllData state
//       setAllData((prevData) =>
//         prevData.filter((item) => item.item_id !== userIdToDelete)
//       );

//       // Reset states
//       setUserIdToDelete(null);
//     } catch (error) {
//       console.log("Error deleting item:", error);
//     } finally {
//       // Close the modal
//       const modal = window.bootstrap.Modal.getInstance(
//         document.getElementById("deleteConfirmationModal2")
//       );
//       if (modal) modal.hide();
//     }
//   };
//   const handleSetSingleData2 = async (item_id) => {
//     try {
//       const itemData = await ShowItemsApi(item_id); // Fetch item details by ID
//       setItemData(itemData);

//       const adminCookie = cookies.restaurant_id;
//       if (!adminCookie) {
//         console.error("No restaurant_id in cookies.");
//         return;
//       }
//       // Fetch all categories using GetCategoryMenuApi
//       const allCategories = await GetCategoryMenuApi(adminCookie.restaurant_id); // Ensure restaurant_id is available
//       setAllCatData(allCategories);

//       // Find the category by itemData.category_id from the fetched categories
//       const selectedCategory = allCategories.find(
//         (category) => category.category_id === itemData.category_id
//       );

//       if (selectedCategory) {
//         setCategoryName(selectedCategory.category_name); // Set the category name
//         setCategoryId(selectedCategory.category_id); // Store the category ID
//       }

//       setItemName(itemData.item_name || ""); // Set item name
//       setItemPrice(itemData.price || ""); // Set item price
//       setFoodType(itemData.type || ""); // Set food type
//       setItemId(itemData.item_id || ""); // Set food type

//       const item = AllitemData.find((i) => i.item_id === item_id);
//       if (item) {
//         setSelectedCategory(item.category_id);
//         setItemName(item.item_name);
//         setItemPrice(item.price);
//         setFoodType(item.type);
//         setItemId(item.item_id);
//         setImage(item.image); // This assumes you have an image state
//       }

//       setisUpdate(true);

//       if (itemData.image) {
//         setImage(itemData.image); // Set item image if available
//       }
//       // Show the modal (assuming you're using Bootstrap)
//       const modal = new window.bootstrap.Modal(
//         document.getElementById("editItems")
//       );
//       modal.show();
//     } catch (error) {
//       console.log("Error fetching item or category data:", error);
//     }
//   };
//   const handleEditItem = async (e) => {
//     e.preventDefault();

//     if (!itemName || !itemPrice || !selectedCategory || !foodType) {
//       console.log("Missing required fields");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("category_id", selectedCategory);
//     formData.append("item_name", itemName);
//     formData.append("price", itemPrice);
//     formData.append("type", foodType);
//     if (image) {
//       formData.append("image", image);
//     }

//     try {
//       await toast.promise(EditItemsApi(itemId, formData), {
//         loading: "Wait a few moments...",
//         success: "Item updated successfully!",
//         error: "Failed to update item.",
//       });
//       setisUpdate();

//       const modalElement = document.getElementById("editItems");
//       if (modalElement) {
//         const modalInstance = bootstrap.Modal.getInstance(modalElement);
//         if (modalInstance) {
//           modalInstance.hide();
//         }
//       }
//     } catch (error) {
//       console.error("Error editing item:", error);
//     }
//   };

//   const handleAddItem = async (e) => {
//     e.preventDefault();
//     const { restaurant_id } = cookies.restaurant_id || {};
//     if (!itemName || !itemPrice || !categoryId || !foodType) {
//       console.log("Missing required fields");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("category_id", categoryId);
//     formData.append("item_name", itemName);
//     formData.append("price", itemPrice);
//     formData.append("type", foodType);
//     formData.append("restaurant_id", restaurant_id); // Add restaurant_id to form data

//     if (image) {
//       formData.append("image", image);
//     }

//     try {
//       await toast.promise(AddItemsApi(formData), {
//         loading: "Adding item...",
//         success: "Item added successfully!",
//         error: "Failed to add item.",
//       });
//       setisUpdate();

//       const modal = window.bootstrap.Modal.getInstance(
//         document.getElementById("AddItems")
//       );
//       if (modal) modal.hide();
//     } catch (error) {
//       console.log("Error adding item:", error);
//     }
//   };

//   useEffect(() => {
//     const addCategoryModal = document.getElementById("AddCategorys");
//     const editCategoryModal = document.getElementById("editCategorys");
//     const addTableModal = document.getElementById("AddItems");
//     const editTableModal = document.getElementById("editItems");
//     const handleModalClose = () => window.location.reload();
//     if (editCategoryModal) {
//       editCategoryModal.addEventListener("hidden.bs.modal", handleModalClose);
//     }
//     if (addCategoryModal) {
//       addCategoryModal.addEventListener("hidden.bs.modal", handleModalClose);
//     }
//     if (addTableModal) {
//       addTableModal.addEventListener("hidden.bs.modal", handleModalClose);
//     }
//     if (editTableModal) {
//       editTableModal.addEventListener("hidden.bs.modal", handleModalClose);
//     }

//     return () => {
//       if (editCategoryModal) {
//         editCategoryModal.removeEventListener(
//           "hidden.bs.modal",
//           handleModalClose
//         );
//       }
//       if (editTableModal) {
//         editTableModal.removeEventListener("hidden.bs.modal", handleModalClose);
//       }
//       if (addCategoryModal) {
//         addCategoryModal.removeEventListener(
//           "hidden.bs.modal",
//           handleModalClose
//         );
//       }
//     };
//   }, []);

//   // Function to open the modal with the selected image
//   const handleImageClick = (image) => {
//     setPreviewImage(image);
//   };

//   // Function to close the modal
//   const handleCloseModal = () => {
//     setPreviewImage(null);
//   };
//   return (
//     <div>
//       {<Sidebar />}
//       <div className="asside">
//         <div className="about-first">
//           <div className="">
//             <div className="page-heading">
//               <h3>Create Menu</h3>
//             </div>
//             <div className="bg-box">
//               <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
//                 <li class="nav-item ms-0" role="presentation">
//                   <button
//                     class="nav-link active"
//                     id="pills-home-tab"
//                     data-bs-toggle="pill"
//                     data-bs-target="#pills-home"
//                     type="button"
//                     role="tab"
//                     aria-controls="pills-home"
//                     aria-selected="true"
//                   >
//                     {" "}
//                     Items{" "}
//                   </button>
//                 </li>
//                 <li class="nav-item" role="presentation">
//                   <button
//                     class="nav-link"
//                     id="pills-profile-tab"
//                     data-bs-toggle="pill"
//                     data-bs-target="#pills-profile"
//                     type="button"
//                     role="tab"
//                     aria-controls="pills-profile"
//                     aria-selected="false"
//                   >
//                     {" "}
//                     Categorys{" "}
//                   </button>
//                 </li>
//               </ul>
//               <div class="tab-content" id="pills-tabContent">
//                 <div
//                   class="tab-pane fade show active"
//                   id="pills-home"
//                   role="tabpanel"
//                   aria-labelledby="pills-home-tab"
//                 >
//                   <div className="row">
//                     <div className="col-12">
//                       <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
//                         <p className="mb-0">All Items</p>
//                         <Link
//                           to={"/Dashboard"}
//                           type="submit"
//                           className="btn btn-login w-auto p-2"
//                           data-bs-toggle="modal"
//                           data-bs-target="#AddItems"
//                         >
//                           Add Items
//                         </Link>
//                       </div>
//                     </div>

//                     <div
//                       class="tab-pane fade show active"
//                       id="pills-home"
//                       role="tabpanel"
//                       aria-labelledby="pills-home-tab"
//                     >
//                       <div className="d-flex align-items-end justify-content-between mb-3">
//                         <div className="d-flex">
//                           <div className=" me-3">
//                             <label className="mb-2">Select Category</label>
//                             <select
//                               className="form-select form-control"
//                               id=""
//                               aria-label="Floating label select example"
//                               value={selectedCategory2}
//                               onChange={(e) =>
//                                 setSelectedCategory2(e.target.value)
//                               }
//                             >
//                               <option value="" selected>
//                                 Select All category
//                               </option>
//                               {AllCatFilterData.map((category) => (
//                                 <option
//                                   key={category.category_id}
//                                   value={category.category_id}
//                                 >
//                                   {category.category_name}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </div>
//                         <div className="form-floating small-floating">
//                           <input
//                             type="text"
//                             className="form-control py-2 w-auto"
//                             id="floatingInput"
//                             placeholder="name@example.com"
//                             value={searchInput2}
//                             onChange={(e) => setSearchInput2(e.target.value)}
//                           />
//                           <label for="floatingInput">Search</label>
//                         </div>
//                       </div>
//                       <div className="responsive-table">
//                         <table className="table border-tbl">
//                           <thead>
//                             <tr>
//                               <th>No</th>
//                               <th>Category</th>
//                               <th>Item Name</th>
//                               <th>Price</th>
//                               <th>Food Type</th>
//                               <th>Image</th>
//                               <th>Action</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {filteredData2 && filteredData2.length > 0 ? (
//                               filteredData2.map((item, index) => (
//                                 <tr key={item.item_id}>
//                                   <td>{index + 1}</td>
//                                   <td>{item.category_name}</td>
//                                   <td>{item.item_name}</td>
//                                   <td>{item.price}</td>
//                                   <td>{item.type}</td>
//                                   <td>
//                                     <div className="item-img">
//                                       <img
//                                         src={item.image}
//                                         className="img-fluid"
//                                         alt={item.item_name}
//                                         style={{
//                                           //width: "50px",
//                                           cursor: "pointer",
//                                         }} // Small size, clickable
//                                         onClick={() =>
//                                           handleImageClick(item.image)
//                                         } // Click event to open the modal
//                                       />
//                                     </div>
//                                   </td>
//                                   <td>
//                                     <div className="icon-up-del">
//                                       <Link
//                                         type="button"
//                                         data-bs-toggle="modal"
//                                         data-bs-target="#editItems"
//                                         onClick={() => {
//                                           console.log("Item ID:", item.item_id); // Ensure item_id is correct
//                                           handleSetSingleData2(item.item_id);
//                                         }}
//                                       >
//                                         <i className="fa-solid fa-edit text-success"></i>
//                                       </Link>
//                                       <Link
//                                         type="button"
//                                         onClick={() =>
//                                           handleDeleteClick2(item.item_id)
//                                         }
//                                       >
//                                         <i className="fa-solid fa-trash text-danger"></i>
//                                       </Link>
//                                     </div>
//                                   </td>
//                                 </tr>
//                               ))
//                             ) : (
//                               <tr>
//                                 <td colSpan="3">No items available</td>
//                               </tr>
//                             )}
//                             {/* <tr>
//                               <td>2</td>
//                               <td>Sweets</td>
//                               <td>Jalebi & Fafda</td>
//                               <td>100</td>
//                               <td>Non-Veg</td>
//                               <td>
//                                 <div className="item-img">
//                                   <img src={user} className="img-fluid" />
//                                 </div>
//                               </td>
//                               <td>
//                                 <div className="icon-up-del">
//                                   <Link
//                                     type="button"
//                                     data-bs-toggle="modal"
//                                     data-bs-target="#editItems"
//                                   >
//                                     <i className="fa-solid fa-edit text-success"></i>
//                                   </Link>
//                                   <Link>
//                                     <i className="fa-solid fa-trash text-danger"></i>
//                                   </Link>
//                                 </div>
//                               </td>
//                             </tr> */}
//                           </tbody>
//                         </table>
//                       </div>
//                       {previewImage && (
//                         <div className="modal show d-block" role="dialog">
//                           <div
//                             className="modal-dialog modal-lg"
//                             role="document"
//                           >
//                             <div className="modal-content">
//                               <div className="modal-header">
//                                 <h5 className="modal-title">Image Preview</h5>
//                                 <button
//                                   type="button"
//                                   className="btn-close"
//                                   onClick={handleCloseModal}
//                                 ></button>
//                               </div>
//                               <div className="modal-body text-center">
//                                 <img
//                                   src={previewImage}
//                                   alt="Preview"
//                                   className="img-fluid"
//                                   style={{ maxWidth: "100%" }}
//                                 />
//                               </div>
//                               <div className="modal-footer">
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary"
//                                   onClick={handleCloseModal}
//                                 >
//                                   Close
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </div>

//                 <div
//                   class="tab-pane fade"
//                   id="pills-profile"
//                   role="tabpanel"
//                   aria-labelledby="pills-profile-tab"
//                 >
//                   <div className="row">
//                     <div className="col-12">
//                       <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
//                         <p className="mb-0">All Categorys</p>
//                         <Link
//                           to={"/Dashboard"}
//                           type="submit"
//                           className="btn btn-login w-auto p-2"
//                           data-bs-toggle="modal"
//                           data-bs-target="#AddCategorys"
//                         >
//                           Add Categorys
//                         </Link>
//                       </div>
//                     </div>
//                     <div
//                       class="tab-pane fade show active"
//                       id="pills-home"
//                       role="tabpanel"
//                       aria-labelledby="pills-home-tab"
//                     >
//                       <div className="d-flex align-items-end justify-content-between mb-3">
//                         <div className="d-flex"></div>
//                         <div className="form-floating small-floating">
//                           <input
//                             type="text"
//                             className="form-control py-2 w-auto"
//                             id="floatingInput"
//                             placeholder="name@example.com"
//                             value={searchInput}
//                             onChange={(e) => setSearchInput(e.target.value)}
//                           />
//                           <label for="floatingInput">Search</label>
//                         </div>
//                       </div>
//                       <div className="responsive-table">
//                         <table className="table border-tbl">
//                           <thead>
//                             <tr>
//                               <th>No</th>
//                               <th>Category Name</th>
//                               <th>Action</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {filteredData && filteredData.length > 0 ? (
//                               filteredData.map((category, index) => (
//                                 <tr key={category.category_id}>
//                                   <td>{index + 1}</td>
//                                   <td>{category.category_name}</td>
//                                   <td>
//                                     <div className="icon-up-del">
//                                       <Link
//                                         type="button"
//                                         data-bs-toggle="modal"
//                                         data-bs-target="#editCategorys"
//                                         onClick={() =>
//                                           handleSetSingleData(category)
//                                         }
//                                       >
//                                         <i className="fa-solid fa-edit text-success"></i>
//                                       </Link>
//                                       <Link
//                                         type="button"
//                                         onClick={() =>
//                                           handleDeleteClick(
//                                             category.category_id
//                                           )
//                                         }
//                                       >
//                                         <i className="fa-solid fa-trash text-danger"></i>
//                                       </Link>
//                                     </div>
//                                   </td>
//                                 </tr>
//                               ))
//                             ) : (
//                               <tr>
//                                 <td colSpan="3">No categories available</td>
//                               </tr>
//                             )}
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* <!-- Add Items --> */}
//       <div
//         className="modal fade"
//         id="AddItems"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="exampleModalLabel">
//                 Add Item
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form className="row" onSubmit={handleAddItem}>
//                 <div className="mb-3 col-12">
//                   <label className="mb-2">Select Category</label>
//                   <select
//                     className="form-select form-control"
//                     value={categoryId}
//                     onChange={(e) => setCategoryId(parseInt(e.target.value))}
//                     aria-label="Select category"
//                   >
//                     <option value="" selected disabled>
//                       Select category
//                     </option>
//                     {AllData.map((category) => (
//                       <option
//                         key={category.category_id}
//                         value={category.category_id}
//                       >
//                         {category.category_name}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//                 <div className="mb-3 col-12">
//                   <label className="mb-2">Item Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     value={itemName}
//                     onChange={(e) => setItemName(e.target.value)}
//                   />
//                 </div>
//                 <div className="mb-3 col-12">
//                   <label className="mb-2">Price</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     value={itemPrice}
//                     onChange={(e) => setItemPrice(e.target.value)}
//                   />
//                 </div>
//                 <div className="mb-3 col-12">
//                   <label className="mb-2">Food Type</label>
//                   <select
//                     className="form-select form-control"
//                     value={foodType}
//                     onChange={(e) => setFoodType(e.target.value)}
//                     aria-label="Food type"
//                   >
//                     <option value="" disabled>
//                       Select Food Type
//                     </option>
//                     <option value="veg">Veg</option>
//                     <option value="non-veg">Non-Veg</option>
//                   </select>
//                 </div>
//                 <div className="mb-3 col-12">
//                   <label className="mb-2">Image</label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     onChange={(e) => setImage(e.target.files[0])}
//                   />
//                 </div>
//                 <div className="upload-reset-btn mb-0 justify-content-center pt-2">
//                   <button
//                     type="button"
//                     className="btn btn-reset"
//                     data-bs-dismiss="modal"
//                   >
//                     Cancel
//                   </button>
//                   <button type="submit" className="btn btn-upload me-0">
//                     Add
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <!--End Add Items --> */}

//       {/* <!-- Edit Items --> */}
//       <div
//         className="modal fade"
//         id="editItems"
//         tabindex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="exampleModalLabel">
//                 Edit Item
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <div className="user-details">
//                 <form className="row" onSubmit={handleEditItem}>
//                   <div className="mb-3 col-12">
//                     <label clas sName="mb-2">
//                       Select Category
//                     </label>
//                     <select
//                       className="form-select form-control"
//                       value={selectedCategory}
//                       onChange={(e) => setSelectedCategory(e.target.value)}
//                     >
//                       <option value="" disabled>
//                         Select category
//                       </option>
//                       {AllCatData.map((category) => (
//                         <option
//                           key={category.category_id}
//                           value={category.category_id}
//                         >
//                           {category.category_name}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Item Name</label>
//                     <input
//                       type="text"
//                       value={itemName}
//                       onChange={(e) => setItemName(e.target.value)}
//                       className="form-control"
//                     />
//                   </div>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Price</label>
//                     <input
//                       type="text"
//                       value={itemPrice}
//                       onChange={(e) => setItemPrice(e.target.value)}
//                       className="form-control"
//                     />
//                   </div>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Item ID</label>
//                     <input
//                       type="text"
//                       value={itemId}
//                       onChange={(e) => setItemId(e.target.value)}
//                       className="form-control"
//                     />
//                   </div>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Select Food Type</label>
//                     <select
//                       className="form-select form-control"
//                       value={foodType}
//                       onChange={(e) => setFoodType(e.target.value)}
//                     >
//                       <option value="" disabled>
//                         Select Food Type
//                       </option>
//                       <option value="veg">Veg</option>
//                       <option value="non-veg">Non-Veg</option>
//                     </select>
//                   </div>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Image</label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       onChange={(e) => setImage(e.target.files[0])}
//                     />
//                   </div>
//                   <div className="upload-reset-btn mb-0 justify-content-center pt-2">
//                     <button
//                       className="btn btn-reset"
//                       type="button"
//                       data-bs-dismiss="modal"
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       className="btn btn-upload me-0"
//                       type="submit"
//                       data-bs-dismiss="modal"
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <!--End Edit Items --> */}

//       {/* <!-- Add Categorys --> */}
//       <div
//         className="modal fade"
//         id="AddCategorys"
//         tabindex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="exampleModalLabel">
//                 Add Category
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <div className="user-details">
//                 <form className="row" onSubmit={handleAddCategory}>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Category Name</label>
//                     <input
//                       type="text"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                       className="form-control"
//                     />
//                   </div>
//                   <div className="upload-reset-btn mb-0 justify-content-center pt-2">
//                     <button
//                       className="btn btn-reset"
//                       type="button"
//                       data-bs-dismiss="modal"
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       className="btn btn-upload me-0"
//                       data-bs-dismiss="modal"
//                       type="submit"
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <!--End Add Categorys --> */}

//       {/* <!-- Edit Categorys --> */}
//       <div
//         className="modal fade"
//         id="editCategorys"
//         tabindex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="exampleModalLabel">
//                 Edit Category
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <div className="user-details">
//                 <form className="row" onSubmit={handleUpdateCategory}>
//                   <div className="mb-3 col-12">
//                     <label className="mb-2">Category Name</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       value={categoryName}
//                       onChange={(e) => setCategoryName(e.target.value)}
//                     />
//                   </div>
//                   <div className="upload-reset-btn mb-0 justify-content-center pt-2">
//                     <button
//                       className="btn btn-reset"
//                       id="closeUpdateCategory"
//                       data-bs-dismiss="modal"
//                       type="button"
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       type="submit" // Ensure this is a submit button
//                       className="btn btn-upload me-0"
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <UpdateModel
//         item={SignleData}
//         setItem={setSignleData}
//         isUpdate={isUpdate}
//         setisUpdate={setisUpdate}
//       /> */}
//       {/* <!--End Edit Categorys --> */}
//       <div
//         className="modal fade"
//         id="deleteConfirmationModal"
//         tabIndex="-1"
//         aria-labelledby="deleteConfirmationModalLabel"
//         aria-hidden="true"
//         //style={{ visibility: "visible" }} // For debugging
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="deleteConfirmationModalLabel">
//                 Confirm Deletion
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close bg-white"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <h4 className="py-4 text-center">
//                 Are you sure you want to delete this Category ?
//               </h4>
//               <div className="d-flex justify-content-center pt-2">
//                 <button
//                   className="btn btn-reset me-2"
//                   data-bs-dismiss="modal"
//                   onClick={handleDeleteCategory}
//                 >
//                   Yes
//                 </button>
//                 <button className="btn btn-upload" data-bs-dismiss="modal">
//                   No
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className="modal fade"
//         id="deleteConfirmationModal2"
//         tabIndex="-1"
//         aria-labelledby="deleteConfirmationModalLabel"
//         aria-hidden="true"
//         //style={{ visibility: "visible" }} // For debugging
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="deleteConfirmationModalLabel2">
//                 Confirm Deletion
//               </h5>
//               <button
//                 type="button"
//                 className="btn-close bg-white"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <h4 className="py-4 text-center">
//                 Are you sure you want to delete this Item ?
//               </h4>
//               <div className="d-flex justify-content-center pt-2">
//                 <button
//                   className="btn btn-reset me-2"
//                   data-bs-dismiss="modal"
//                   onClick={handleDeleteItem}
//                 >
//                   Yes
//                 </button>
//                 <button className="btn btn-upload" data-bs-dismiss="modal">
//                   No
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateMenu;
