import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import { ForgotPasswordApi } from "../helper/integration";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
const ForgotPassword = () => {
  const navigate = useNavigate();

  const { register, reset, handleSubmit } = useForm();

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");
  const handleForgotPassword = (data) => {
    if (data?.password !== data?.confirm_password)
      return toast.error("Passwords are Not Matching..!");

    let obj = {
      email: email, //cookie.admin,
      ...data,
    };

    toast
      .promise(ForgotPasswordApi(obj), {
        loading: "Wait Few Moment !",
        success: "Password Updated Successfully !",
        error: "Password is wrong !",
      })
      .then(() => {
        console.log(email, obj);
        reset();
        navigate("/");
      })
      .catch(() => {});
  };
  useEffect(() => {
    //if (!cookie.admin) window.location = "/";
  });

  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2 className="mb-0">Creat New Password</h2>
                </div>
                <div className="col-4 text-end">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleSubmit(handleForgotPassword)}>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("password", { required: true })}
                  />
                  <label for="floatingInput">New Password</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("confirm_password", { required: true })}
                  />
                  <label for="floatingPassword">Confirm New Password</label>
                </div>
                <button
                  to={"/Dashboard"}
                  type="submit"
                  className="btn btn-login"
                >
                  Confirm
                </button>
              </form>
              <div className="create-acc">
                <p>
                  Already have an account?{" "}
                  <Link to={"/"}> Sign in instead</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
