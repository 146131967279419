import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import user from "../Image/user.png";
import { useCookies } from "react-cookie";
import { GetRequestOrderApi } from "../helper/integration";

const Sidebar = () => {
  const [cookie, setcookie, removecookie] = useCookies(["restaurant_id"]);
  const [cookies] = useCookies(["restaurant_id"]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [AllOrdersData, setAllOrdersData] = useState([]);
  const restaurant_id = cookies.restaurant_id?.restaurant_id;
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookie.restaurant_id) window.location = "/";
  }, [cookie]);
  const location = useLocation();

  useEffect(() => {
    const fetchOrders = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          const data = await GetRequestOrderApi(adminCookie.restaurant_id);

          // let filteredOrders = data.filter(
          //   (order) => order.table_id === selectedTableId // selectedTableId should be the current active tab's table_id
          // );

          // const formattedSelectedTime = selectedTime
          //   ? selectedTime.padEnd(5, ":00")
          //   : null;
          console.log("Data:", data);

          const filteredOrders = data.filter(
            (order) => order.status === "Pending"
          );
          console.log(filteredOrders);
          const groupOrders = Object.values(
            data.reduce((acc, order) => {
              const { order_id } = order;
              if (!acc[order_id]) {
                acc[order_id] = {
                  order_id,
                  items: [],
                  ...order,
                };
              }
              //   acc[order_id].items.push({
              //     item_id: order.item_id,
              //     item_name: order.item_name,
              //     item_qty: order.item_qty,
              //     qty_total: order.qty_total,
              //     image: order.image,
              //   });
              return acc;
            }, {})
          );
          setAllOrdersData(groupOrders);
          console.log(groupOrders);
        } catch (error) {
          console.error("Failed to fetch categories:", error);
          console.log("Failed to fetch categories:", error);
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    fetchOrders();
  }, [cookies, restaurant_id, searchQuery]);

  return (
    <div>
      {/* nav fixx */}
      <div className="sidebar">
        <div className="sidebar-logo">
          <Link to={"/Dashboard"}>
            <img src={logo} className="img-fluid" />
          </Link>
        </div>
        <div className="overflow-y">
          <div className="sidebar-link">
            <ul>
              <li
                className={location.pathname === "/Dashboard" ? "active" : ""}
              >
                <NavLink to={"/Dashboard"}>Dashboard</NavLink>
              </li>
              <li
                className={
                  location.pathname === "/RequestOrder" ? "active" : ""
                }
              >
                <NavLink to={"/RequestOrder"} className="position-relative">
                  Request Order{" "}
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                    {AllOrdersData.filter((order) => order.status === "Pending")
                      .length > 99
                      ? "99+"
                      : AllOrdersData.filter(
                          (order) => order.status === "Pending"
                        ).length}
                    +<span class="visually-hidden">unread messages</span>
                  </span>
                </NavLink>
              </li>
              <li
                className={
                  location.pathname === "/OrderHistory" ? "active" : ""
                }
              >
                <NavLink to={"/OrderHistory"}>Order History</NavLink>
              </li>
              <li
                className={location.pathname === "/OrderInfo" ? "active" : ""}
              >
                <NavLink to={"/OrderInfo"}>Order Info</NavLink>
              </li>
              <li
                className={location.pathname === "/AcceptOrder" ? "active" : ""}
              >
                <NavLink to={"/AcceptOrder"}>Accept Order</NavLink>
              </li>
              <li className={location.pathname === "#" ? "active" : ""}>
                <NavLink to={"#"}>Edit Menu</NavLink>
              </li>
              <li className={location.pathname === "#" ? "active" : ""}>
                <NavLink to={"#"}>Review</NavLink>
              </li>
              <p className="text-light">- Settings</p>
              <li className={location.pathname === "/AddTable" ? "active" : ""}>
                <NavLink to={"/AddTable"}>Add Table</NavLink>
              </li>
              <li
                className={location.pathname === "/AddWaiter" ? "active" : ""}
              >
                <NavLink to={"/AddWaiter"}>Add Waiter</NavLink>
              </li>
              <li
                className={location.pathname === "/CreateMenu" ? "active" : ""}
              >
                <NavLink to={"/CreateMenu"}>Create Menu</NavLink>
              </li>
              <li
                onClick={() => {
                  removecookie("restaurant_id");
                  navigate("/#");
                }}
                className={location.pathname === "/#" ? "active" : ""}
              >
                <NavLink to={"/#"}>Log Out</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className='fixx'>
                <nav className="top-header navbar navbar-expand-lg sticky-top navbar-light">
                    <div className="py-2 container-fluid">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control border-0" placeholder="Search"/>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse navheight justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav navbarbottom mb-lg-0 align-items-lg-center">
                                <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                                    <Link className="nav-link hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                        <NavLink className="nav-link bell" aria-current="page" to={"#"} ><i className="fa-regular fa-bell"></i></NavLink>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end py-0" data-bs-popper="static">
                                        <li className="dropdown-menu-header border-bottom">
                                            <div className="dropdown-header d-flex align-items-center py-3">
                                                <h5 className="text-body mb-0 me-auto">Notification</h5>
                                                <Link to={"#"} className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Mark all as read" data-bs-original-title="Mark all as read"><i className="fa-regular fa-envelope-open"></i></Link>
                                            </div>
                                        </li>
                                        <li className="dropdown-notifications-list scrollable-container ps ps--active-y">
                                            <ul className="list-group list-group-flush all-project-table">
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                                                            <p className="mb-0">Won the monthly best seller gold badge</p>
                                                            <small className="text-muted">1h ago</small>
                                                        </div>
                                                        <div className="flex-shrink-0 dropdown-notifications-actions">
                                                            <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                            <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Charles Franklin</h6>
                                                        <p className="mb-0">Accepted your connection</p>
                                                        <small className="text-muted">12hr ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">New Message ✉️</h6>
                                                        <p className="mb-0">You have new message from Natalie</p>
                                                        <small className="text-muted">1h ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Whoo! You have new order 🛒 </h6>
                                                        <p className="mb-0">ACME Inc. made new order $1,154</p>
                                                        <small className="text-muted">1 day ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Application has been approved 🚀 </h6>
                                                        <p className="mb-0">Your ABC project application has been approved.</p>
                                                        <small className="text-muted">2 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Monthly report is generated</h6>
                                                        <p className="mb-0">July monthly financial report is generated </p>
                                                        <small className="text-muted">3 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">Send connection request</h6>
                                                        <p className="mb-0">Peter sent you connection request</p>
                                                        <small className="text-muted">4 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                                    <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <div className="avatar">
                                                            <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-1">New message from Jane</h6>
                                                        <p className="mb-0">Your have new message from Jane</p>
                                                        <small className="text-muted">5 days ago</small>
                                                    </div>
                                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                                        <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                        <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                    </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <img src={user} className="w-px-40 h-auto rounded-circle"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mb-1">CPU is running high</h6>
                                                            <p className="mb-0">CPU Utilization Percent is currently at 88.63%,</p>
                                                            <small className="text-muted">5 days ago</small>
                                                        </div>
                                                        <div className="flex-shrink-0 dropdown-notifications-actions">
                                                            <Link to={"#"} className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                            <Link to={"#"} className="dropdown-notifications-archive"><span className="bx bx-x"></span></Link>
                                                        </div>
                                                    </div>
                                                </li>   
                                            </ul>
                                        </li>
                                        <li className="dropdown-menu-footer border-top p-3">
                                            <button className="btn add-new text-uppercase w-100">view all notifications</button>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <button type="button" className="navbar-user position-relative border-0 bg-transparent" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={user} />
                                        <span className="position-absolute bottom-0 start-100 translate-middle p-1 bg-light-green border border-light rounded-circle">
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end header-drop">
                                        <Link className="dropdown-item" to="/ChangePassword"><span key="t-logout">Change Password</span></Link>
                                        <Link className="dropdown-item" to="/"><span key="t-logout">LogOut</span></Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div> */}
    </div>
  );
};

export default Sidebar;
