import { Api } from "../utils/Api";
import axios from "axios";

// Login Api
export const LoginApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api?.LoginApi, obj);
      console.log(data.data);
      resolve(data.data);
      console.log(data.data);
    } catch (er) {
      reject(er);
    }
  });
};

// Forgot Password Api
export const ForgotPasswordApi = (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.ForgotPasswordApi, obj);
      resolve();
    } catch (er) {
      reject(er);
    }
  });
};

// Email Link Api
export const AdminEmailLinkApi = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new URLSearchParams();
      formData.append("email", email);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const { data } = await axios.post(Api.EmailLinkApi, formData, config); // Adjust the endpoint accordingly
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

//Create Menu Category Api Start
export const GetCategoryMenuApi = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is defined
    if (!Api?.ShowCategoryApi) {
      throw new Error("API endpoint for ShowCategoryApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ShowCategoryApi, {
      restaurant_id,
    });
    console.log(response);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure.");
    }
  } catch (error) {
    console.error("Error in GetCategoryMenuApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const GetCategoryMenu2Api = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is defined
    if (!Api?.ShowCategoryApi) {
      throw new Error("API endpoint for ShowCategoryApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ShowCategoryApi, {
      restaurant_id,
    });

    console.log("Full API Response:", response);

    // Check for a successful response
    if (response.data && response.data.status === "success") {
      // Assuming the categories data is in response.data.data
      if (response.data.data && Array.isArray(response.data.data)) {
        return response.data.data; // Return the categories data
      } else {
        throw new Error("No categories found in response data.");
      }
    } else {
      throw new Error(response.data.message || "Error fetching categories.");
    }
  } catch (error) {
    console.error("Error in GetCategoryMenu2Api:", error);
    throw error; // Re-throw to handle it in the calling function
  }
};

export const UpdateCategoryApi = async (category_id, category_name) => {
  try {
    const { data } = await axios.post(Api.EditCategoryApi, {
      category_id,
      category_name,
    });
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in EditCategoryApi:", er);
    throw er;
  }
};

export const AddCategoryApi = async (obj) => {
  try {
    const { data } = await axios.post(Api.AddCategoryApi, obj);
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in AddCategoryApi:", er);
    throw er;
  }
};

export const RemoveCategoryApi = async (category_id) => {
  //return new Promise(async (resolve, reject) => {
  try {
    const { response } = await axios.post(Api.DeleteCategoryApi, {
      category_id,
    });
    //console.log(response);
    console.log(response);
    return response; // Ensure this is what your backend returns
  } catch (error) {
    console.log("Error deleting category:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
  //});
};
//Create Menu Category Api End

export const POSTAPI = (url, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(url, obj);
      resolve(data);
    } catch (er) {
      reject(er);
    }
  });
};

// Dashboard Tables Api Start
export const GetTablesApi = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is define

    if (!Api?.ShowTableApi) {
      throw new Error("API endpoint for ShowTablesApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ShowTableApi, {
      restaurant_id,
    });
    console.log(response);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure.");
    }
  } catch (error) {
    console.error("Error in GetTablesApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const AddTableApi = async (obj) => {
  try {
    const { data } = await axios.post(Api.AddTableApi, obj);
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in AddTableApi:", er);
    throw er;
  }
};

export const EditTableApi = async (obj) => {
  try {
    const { data } = await axios.post(Api.EditTableApi, obj);
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in EditTableApi:", er);
    throw er;
  }
};

export const DeleteTableApi = (table_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DeleteTableApi, { table_id });
      console.log(data);
      resolve(data); // Return the data object
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};
// Dashboard Tables Api End

export const ShowItemApi = async (restaurant_id, item_id) => {
  try {
    // Ensure Api.ShowItemsApi is defined
    if (!Api?.ShowItemsApi) {
      throw new Error("API endpoint for ShowItemsApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ShowItemsApi, {
      restaurant_id,
      item_id,
    });

    console.log(response, restaurant_id, item_id);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response.data);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure.");
    }
  } catch (error) {
    console.error("Error in ShowItemsApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const DeleteItemsApi = (item_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DeleteItemsApi, { item_id });
      console.log(data);
      resolve(data); // Return the data object
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const EditItemsApi = (item_id, formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Append the item_id to the formData
      formData.append("item_id", item_id);

      const { data } = await axios.post(Api.EditItemsApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the request has the right content type
        },
      });

      console.log(data);

      if (data.status === "success") {
        resolve(data);
      } else {
        console.log(data);
        reject(new Error(data.message || "Failed to update item"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const AddItemsApi = (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.AddItemsApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(data);

      if (data.status === "success") {
        resolve(data);
      } else {
        console.log(data);
        reject(new Error(data.message || "Failed to add item"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
//Create Menu Category Api End

//Add Waiter Function Start
export const GetWaitersApi = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is define

    if (!Api?.ShowWaiterApi) {
      throw new Error("API endpoint for ShowTablesApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ShowWaiterApi, {
      restaurant_id,
    });
    console.log(response);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure.");
    }
  } catch (error) {
    console.error("Error in GetWaiterApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const AddWaiterApi = async (obj) => {
  try {
    const { data } = await axios.post(Api.AddWaiterApi, obj);
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in AddWaiterApi:", er);
    throw er;
  }
};

export const DeleteWaiterApi = (user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(Api.DeleteWaiterApi, { user_id });
      console.log(data);
      resolve(data); // Return the data object
    } catch (er) {
      console.log(er);
      reject(er);
    }
  });
};

export const EditWaiterApi = async (obj) => {
  try {
    const { data } = await axios.post(Api.EditWaiterApi, obj);
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in EditTableApi:", er);
    throw er;
  }
};
//Add Waiter Function End

//Order History Function Start
export const GetOrderHistoryApi = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is define

    if (!Api?.OrderHistoryApi) {
      throw new Error("API endpoint for ShowTablesApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.OrderHistoryApi, {
      restaurant_id,
    });
    console.log(response);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure.");
    }
  } catch (error) {
    console.error("Error in GetWaiterApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const GetViewBillApi = async (table_id) => {
  try {
    // Ensure the API endpoint is defined
    if (!Api?.ViewBillApi) {
      throw new Error("API endpoint for ViewBillApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ViewBillApi, {
      table_id,
    });

    // Log the full response for debugging purposes
    console.log("API Response:", response);

    // Ensure the response contains the expected data
    if (
      response.data &&
      response.data.status === "success" &&
      response.data.data
    ) {
      console.log("Fetched Bill Data:", response.data.data);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      // Handle unexpected response structure or failure
      throw new Error(
        `Unexpected response structure or failure: ${
          response.data.message || "Unknown error"
        }`
      );
    }
  } catch (error) {
    // Log and re-throw the error for handling in the calling function
    console.error("Error in GetViewBillApi:", error);
    throw error;
  }
};

export const GetViewBillApi2 = async (order_id) => {
  try {
    // Ensure the API endpoint is defined
    if (!Api?.ViewBillApi) {
      throw new Error("API endpoint for ViewBillApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.ViewBillApi, {
      order_id,
    });

    // Log the full response for debugging purposes
    console.log("API Response:", response);

    // Ensure the response contains the expected data
    if (
      response.data &&
      response.data.status === "success" &&
      response.data.data
    ) {
      console.log("Fetched Bill Data:", response.data.data);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      // Handle unexpected response structure or failure
      throw new Error(
        `Unexpected response structure or failure: ${
          response.data.message || "Unknown error"
        }`
      );
    }
  } catch (error) {
    // Log and re-throw the error for handling in the calling function
    console.error("Error in GetViewBillApi:", error);
    throw error;
  }
};

export const GetTodayEarningApi = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is define

    if (!Api?.TodayEarningOrdersApi) {
      throw new Error("API endpoint for ShowTablesApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.TodayEarningOrdersApi, {
      restaurant_id,
    });
    console.log(response);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure."); 
    }
  } catch (error) {
    console.error("Error in TodayEarningOrdersApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
//Order History Function End

//Request Order Function Start

//used integration of GetOrderHistoryApi
//used integration of GetViewBillApi

export const GetRequestOrderApi = async (restaurant_id) => {
  try {
    // Ensure Api.ShowCategoryApi is define

    if (!Api?.RequestOrderApi) {
      throw new Error("API endpoint for ShowTablesApi is not defined.");
    }

    // Make the POST request to the API
    const response = await axios.post(Api.RequestOrderApi, {
      restaurant_id,
    });
    console.log(response);

    // Ensure the response contains the expected data
    if (response.data && response.data.data) {
      console.log(response);
      return response.data.data; // Assuming `response.data.data` is the relevant data
    } else {
      throw new Error("Unexpected response structure.");
    }
  } catch (error) {
    console.error("Error in GetWaiterApi:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const EditOrdersReqApi = async (order_id, items) => {
  try {
    const { data } = await axios.post(Api.UpdateOrderReqApi, {
      order_id,
      items,
    });
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in EditTableApi:", er);
    throw er;
  }
};

export const EditOrdersStatusApi = async (orderId, status) => {
  try {
    const { data } = await axios.post(Api.UpdateOrderStatusApi, {
      order_id: orderId,
      status,
    });
    console.log(data);
    return data;
  } catch (er) {
    console.error("Error in EditTableApi:", er);
    throw er;
  }
};

//Request Order Function End
