import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import billlogo from "../Image/bill-logo.svg";
import { useCookies } from "react-cookie";
import {
  GetOrderHistoryApi,
  GetTablesApi,
  GetViewBillApi,
} from "../helper/integration";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const OrderHistory = () => {
  const [AllData, setAllData] = useState([]);
  const [AllOrdersData, setAllOrdersData] = useState([]);
  const [ViewBillData, setViewBillData] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [SignleData, setSignleData] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [tableName, setTableName] = useState(""); // Add state for table name
  const [cookies] = useCookies(["restaurant_id"]);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const restaurant_id = cookies.restaurant_id?.restaurant_id;
  const [billData, setBillData] = useState({
    items: [],
  });

  useEffect(() => {
    (async () => {
      if (!restaurant_id) return;
      try {
        setisLoad(false);
        const data = await GetTablesApi(restaurant_id);
        console.log("Fetched tablesdata:", data);
        setAllData(data); // Ensure data is set correctly
        setSelectedTableId(data[0]?.table_id || null); // Set initial selected table ID to the first table's ID
        setisLoad(true);
      } catch (error) {
        console.error("Error fetching tables data:", error);
      }
    })();
  }, [isUpdate, restaurant_id]);

  //   useEffect(() => {
  //     (async () => {
  //       //if (!restaurant_id) return;
  //       try {
  //         setisLoad(false);
  //         const data = await GetViewBillApi(order_id);
  //         console.log("Fetched tablesdata:", data);
  //         setViewBillData(data); // Ensure data is set correctly
  //         setSelectedOrderId(data[0]?.order_id || null); // Set initial selected table ID to the first table's ID
  //         setisLoad(true);
  //       } catch (error) {
  //         console.error("Error fetching tables data:", error);
  //       }
  //     })();
  //   }, [isUpdate]);

  useEffect(() => {
    const fetchOrders = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          const data = await GetOrderHistoryApi(adminCookie.restaurant_id);

          // let filteredOrders = data.filter(
          //   (order) => order.table_id === selectedTableId // selectedTableId should be the current active tab's table_id
          // );

          // const formattedSelectedTime = selectedTime
          //   ? selectedTime.padEnd(5, ":00")
          //   : null;
          console.log("Selected Date:", selectedDate);
          console.log("Selected Time:", selectedTime);

          const filteredOrders = data.filter(
            (order) =>
              order.table_id === selectedTableId &&
              (!selectedDate || order.date === selectedDate) &&
              (!selectedTime || order.time === selectedTime) // Use formattedSelectedTime
          );
          console.log(filteredOrders);
          const groupOrders = Object.values(
            filteredOrders.reduce((acc, order) => {
              const { order_id } = order;
              if (!acc[order_id]) {
                acc[order_id] = {
                  order_id,
                  items: [],
                  ...order,
                };
              }
              //   acc[order_id].items.push({
              //     item_id: order.item_id,
              //     item_name: order.item_name,
              //     item_qty: order.item_qty,
              //     qty_total: order.qty_total,
              //     image: order.image,
              //   });
              return acc;
            }, {})
          );
          setAllOrdersData(groupOrders);
          console.log(groupOrders);
        } catch (error) {
          console.error("Failed to fetch categories:", error);
          console.log("Failed to fetch categories:", error);
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    fetchOrders();
  }, [cookies, selectedTableId, selectedDate, selectedTime, restaurant_id]);

  const handleViewBillClick = (tableId) => {
    setSelectedTableId(tableId);
    const viewBillModal = new bootstrap.Modal(
      document.getElementById("viewbill")
    );
    viewBillModal.show();
  };

  const handleTableClick = (table) => {
    setSelectedTableId(table.table_id);
    setTableName(table.table_name); // Set the table name for the modal header
  };

  useEffect(() => {
    const fetchBill = async () => {
      if (!selectedTableId) return; // Don't fetch if no order ID is selected

      try {
        const data = await GetViewBillApi(selectedTableId);
        setBillData(data[0]); // Assuming data is an array and we need the first item
      } catch (error) {
        console.error("Failed to fetch bill:", error);
      }
    };

    fetchBill();
  }, [selectedTableId]);

  useEffect(() => {
    const modalElement = document.getElementById("viewbill");
    const handleModalClose = () => {
      window.location.reload();
    };

    // Adding event listener for modal close
    modalElement.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      // Clean up event listener on unmount
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h3>Order History</h3>
              </div>
              <div className="bg-box">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  {AllData.map((table, index) => (
                    <li class="nav-item ms-0" role="presentation">
                      <button
                        className={`nav-link ${
                          table.table_id === selectedTableId ? "active" : ""
                        }`}
                        id={`pills-table-${table.table_id}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-table-${table.table_id}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-table-${table.table_id}`}
                        //aria-selected="false"
                        aria-selected={table.table_id === selectedTableId}
                        onClick={() => handleTableClick(table)} // Call handleTableClick on button click
                      >
                        {`Table No ${table.table_name}`}
                      </button>
                    </li>
                  ))}
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="col-12">
                      <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                        <form class="d-flex">
                          <div class="me-2">
                            <label for="inputEmail4" class="form-label">
                              Select Date
                            </label>
                            <input
                              type="date"
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(e.target.value)}
                              class="form-control"
                            />
                          </div>
                          <div class="me-2">
                            <label for="inputPassword4" class="form-label">
                              Select Time
                            </label>
                            <input
                              type="time"
                              value={selectedTime}
                              onChange={(e) => setSelectedTime(e.target.value)}
                              class="form-control"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div>
                      {AllOrdersData.length > 0 ? (
                        AllOrdersData.map((order, index) => (
                          <React.Fragment key={index}>
                            <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                              <p className="mb-0">
                                {/* Placeholder for order date and time */}
                                {order.date} {order.time}{" "}
                                {/*<span>{order.time}</span> */}
                              </p>
                              <Link
                                to={"/Dashboard"}
                                type="submit"
                                className="btn btn-login w-auto p-2"
                                data-bs-toggle="modal"
                                data-bs-target="#viewbill"
                                onClick={() =>
                                  handleViewBillClick(order.table_id)
                                }
                              >
                                View Bill
                              </Link>
                            </div>
                            <div className="responsive-table">
                              <table className="table">
                                <tbody>
                                  {order.items.map((item, itemIndex) => (
                                    <tr
                                      className="shadow-border"
                                      key={itemIndex}
                                    >
                                      <td>
                                        <div className="profile-img">
                                          <img
                                            src={item.image || user} // Use the image from API or default image
                                            alt={item.item_name}
                                            className="img-fluid"
                                          />
                                          <p>{item.item_name}</p>
                                        </div>
                                      </td>
                                      <td>X{item.item_qty}</td>
                                      <td>₹{item.qty_total}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                          <p>No Orders available</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="col-12">
                      <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                        <form class="d-flex">
                          <div class="me-2">
                            <label for="inputEmail4" class="form-label">
                              Select Date
                            </label>
                            <input type="date" class="form-control" />
                          </div>
                          <div class="me-2">
                            <label for="inputPassword4" class="form-label">
                              Select Time
                            </label>
                            <input type="time" class="form-control" />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div>
                      <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                        <p className="mb-0">
                          21-08-2024 <span>11:50 PM</span>
                        </p>
                        <Link
                          to={"/Dashboard"}
                          type="submit"
                          className="btn btn-login w-auto p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#viewbill"
                        >
                          View Bill
                        </Link>
                      </div>
                      <div className="responsive-table">
                        <table className="table">
                          <tbody>
                            <tr className="shadow-border">
                              <td>
                                <div className="profile-img">
                                  <img src={user} className="img-fluid" />
                                  <p>Jalebi & Fafda</p>
                                </div>
                              </td>
                              <td>X3</td>
                              <td>₹ 180</td>
                            </tr>
                            <tr className="shadow-border">
                              <td>
                                <div className="profile-img">
                                  <img src={user} className="img-fluid" />
                                  <p>Jalebi & Fafda</p>
                                </div>
                              </td>
                              <td>X3</td>
                              <td>₹ 180</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                        <p className="mb-0">
                          21-08-2024 <span>11:50 PM</span>
                        </p>
                        <Link
                          to={"/Dashboard"}
                          type="submit"
                          className="btn btn-login w-auto p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#viewbill"
                        >
                          View Bill
                        </Link>
                      </div>
                      <div className="responsive-table">
                        <table className="table">
                          <tbody>
                            <tr className="shadow-border">
                              <td>
                                <div className="profile-img">
                                  <img src={user} className="img-fluid" />
                                  <p>Jalebi & Fafda</p>
                                </div>
                              </td>
                              <td>X3</td>
                              <td>₹ 180</td>
                            </tr>
                            <tr className="shadow-border">
                              <td>
                                <div className="profile-img">
                                  <img src={user} className="img-fluid" />
                                  <p>Jalebi & Fafda</p>
                                </div>
                              </td>
                              <td>X3</td>
                              <td>₹ 180</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                        <p className="mb-0">
                          21-08-2024 <span>11:50 PM</span>
                        </p>
                        <Link
                          to={"/Dashboard"}
                          type="submit"
                          className="btn btn-login w-auto p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#viewbill"
                        >
                          View Bill
                        </Link>
                      </div>
                      <div className="responsive-table">
                        <table className="table">
                          <tbody>
                            <tr className="shadow-border">
                              <td>
                                <div className="profile-img">
                                  <img src={user} className="img-fluid" />
                                  <p>Jalebi & Fafda</p>
                                </div>
                              </td>
                              <td>X3</td>
                              <td>₹ 180</td>
                            </tr>
                            <tr className="shadow-border">
                              <td>
                                <div className="profile-img">
                                  <img src={user} className="img-fluid" />
                                  <p>Jalebi & Fafda</p>
                                </div>
                              </td>
                              <td>X3</td>
                              <td>₹ 180</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- View Bill --> */}
      <div
        className="modal fade"
        id="viewbill"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                View Bill
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <div className="bill-logo">
                  <img src={billlogo} className="img-fluid" />
                </div>
                <form className="row">
                  <div className="mb-3 col-12">
                    <div className="tbl-history">
                      <div className="tbl-name-date">
                        <h5>
                          Table No - <span>{billData.table_name || "N/A"}</span>
                        </h5>
                        <h6>
                          <span>{billData.datetime || "N/A"}</span>
                        </h6>
                      </div>
                      <table className="table tbl-data mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billData.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.item_name}</td>
                              <td>{item.item_qty}</td>
                              <td>₹ {item.qty_total}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot className="total-price">
                          <tr>
                            <td>Total</td>
                            <td>{billData.total_qty || 0}</td>
                            <td>₹ {billData.total || 0}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="tbl-history">
                      <table className="table tbl-data mb-0">
                        <tbody>
                          <tr>
                            <td>Amount to pay</td>
                            <td>₹ {billData.total || 0}</td>
                          </tr>
                          <tr>
                            <td>GST</td>
                            <td>₹ {billData.gst_amount || 0}</td>
                          </tr>
                        </tbody>
                        <tfoot className="total-price">
                          <tr>
                            <td>Sub Total</td>
                            <td>₹ {billData.billing_total || 0}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button className="btn btn-reset" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                    >
                      Print
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End View Bill --> */}
    </div>
  );
};

export default OrderHistory;
