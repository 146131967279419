import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Counter from "./Counter";
import { useCookies } from "react-cookie";
import {
  GetRequestOrderApi,
  GetViewBillApi2,
  EditOrdersReqApi,
  EditOrdersStatusApi,
} from "../helper/integration";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import toast from "react-hot-toast";
const RequestOrder = () => {
  const [cookies] = useCookies(["restaurant_id"]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [AllOrdersData, setAllOrdersData] = useState([]);
  const [isUpdate, setisUpdate] = useState(false);

  const restaurant_id = cookies.restaurant_id?.restaurant_id;

  const [billData, setBillData] = useState({
    items: [],
  });
  const [isModified, setIsModified] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  useEffect(() => {
    const fetchOrders = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          const data = await GetRequestOrderApi(adminCookie.restaurant_id);

          // let filteredOrders = data.filter(
          //   (order) => order.table_id === selectedTableId // selectedTableId should be the current active tab's table_id
          // );

          // const formattedSelectedTime = selectedTime
          //   ? selectedTime.padEnd(5, ":00")
          //   : null;
          console.log("Data:", data);

          const filteredOrders = data.filter((order) => {
            const isStatusPending = order.status === "Pending";
            const matchesTableName = order.table_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
            const matchesBookId = order.book_id
              .toLowerCase()
              .includes(searchQuery.toLowerCase());

            return isStatusPending && (matchesTableName || matchesBookId);
          });
          console.log(filteredOrders);
          const groupOrders = Object.values(
            filteredOrders.reduce((acc, order) => {
              const { order_id } = order;
              if (!acc[order_id]) {
                acc[order_id] = {
                  order_id,
                  items: [],
                  ...order,
                };
              }
              acc[order_id].items.push({
                item_id: order.item_id,
                item_name: order.item_name,
                item_qty: order.item_qty,
                qty_total: order.qty_total,
                image: order.image,
              });
              return acc;
            }, {})
          );
          setAllOrdersData(groupOrders);
          console.log(groupOrders);
        } catch (error) {
          console.error("Failed to fetch categories:", error);
          console.log("Failed to fetch categories:", error);
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    fetchOrders();
  }, [cookies, restaurant_id, searchQuery]);

  useEffect(() => {
    const modalElement = document.getElementById("viewbill");
    const handleModalClose = () => {
      window.location.reload();
    };

    // Adding event listener for modal close
    modalElement.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      // Clean up event listener on unmount
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const handleViewBillClick = (orderId) => {
    setSelectedOrderId(orderId);
    const viewBillModal = new bootstrap.Modal(
      document.getElementById("viewbill")
    );
    viewBillModal.show();
  };

  const handleModalClose = () => {
    const viewBillModal = bootstrap.Modal.getInstance(
      document.getElementById("viewbill")
    );
    if (viewBillModal) {
      viewBillModal.hide();
    }
    setSelectedOrderId(null);
    setBillData({ items: [] });
  };

  useEffect(() => {
    const fetchBill = async () => {
      if (!selectedOrderId) return; // Don't fetch if no order ID is selected

      try {
        const data = await GetViewBillApi2(selectedOrderId);
        console.log(data);
        setBillData(data[0]); // Assuming data is an array and we need the first item
      } catch (error) {
        console.error("Failed to fetch bill:", error);
      }
    };

    fetchBill();
  }, [selectedOrderId]);

  const handleItemQtyChange = (newQty, item_id) => {
    setIsModified(true); // Set modification state to true
    setBillData((prevData) => ({
      ...prevData,
      items: prevData.items.map((item) =>
        item.item_id === item_id ? { ...item, item_qty: newQty } : item
      ),
    }));
  };

  const handleMarkAsAccepted = async (orderId) => {
    setIsLoading(true); // Start loading

    try {
      await toast.promise(EditOrdersStatusApi(orderId, "Accept"), {
        loading: "Wait few moments...",
        success: "Order is Accepted!",
        error: "Failed to mark order as ready.",
      });

      // Refresh the order list or fetch updated data
      setisUpdate();
      setAllOrdersData((prevOrders) =>
        prevOrders.filter((order) => order.order_id !== orderId)
      );
    } catch (error) {
      console.error("Failed to mark order as ready:", error);
      toast.error("Failed to mark order as ready."); // Show error toast message
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // const handleMarkAsRejected = async (orderId) => {
  //   setIsLoading(true); // Start loading

  //   try {
  //     await toast.promise(EditOrdersStatusApi(orderId, "Reject"), {
  //       loading: "Wait few moments...",
  //       success: "Order is Rejected!",
  //       error: "Failed to mark order as rejected.",
  //     });

  //     // Refresh the order list or fetch updated data
  //     setisUpdate();
  //     setAllOrdersData((prevOrders) =>
  //       prevOrders.filter((order) => order.order_id !== orderId)
  //     );
  //   } catch (error) {
  //     console.error("Failed to mark order as ready:", error);
  //     toast.error("Failed to mark order as ready."); // Show error toast message
  //   } finally {
  //     setIsLoading(false); // Stop loading
  //   }
  // };

  const calculateTotalQty = () => {
    if (billData.items && billData.items.length > 0) {
      // Sum the item_qty values (convert to integer first)
      const totalQty = billData.items.reduce(
        (total, item) => total + parseInt(item.item_qty || 0, 10), // Convert to integer
        0
      );
      return totalQty;
    }
    return 0;
  };

  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="">
            <div className="page-heading">
              <h3>Order Request</h3>
            </div>
            <div className="bg-box">
              <div className="row">
                <div className="col-12">
                  <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                    <p className="mb-0">All Order Request</p>
                  </div>
                </div>
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="d-flex align-items-end justify-content-between mb-3">
                    <div className="d-flex"></div>
                    <div className="form-floating small-floating">
                      <input
                        type="text"
                        className="form-control py-2 w-auto"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <label for="floatingInput">Search</label>
                    </div>
                  </div>
                  <div className="responsive-table">
                    <table className="table border-tbl">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Order ID</th>
                          <th>Table Number</th>
                          <th>View Order</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllOrdersData.length > 0 ? (
                          AllOrdersData.map((order, index) => (
                            <tr key={order.item_id}>
                              <td>{index + 1}</td>
                              <td>#{order.book_id}</td>
                              <td>{order.table_name}</td>
                              <td>
                                <Link
                                  to={"/Dashboard"}
                                  type="submit"
                                  className="btn btn-login w-auto p-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewbill"
                                  onClick={() =>
                                    handleViewBillClick(order.order_id)
                                  }
                                >
                                  View Order
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={"#"}
                                  type="submit"
                                  className="btn btn-ready w-auto p-2 m-2"
                                  onClick={() =>
                                    handleMarkAsAccepted(order.order_id)
                                  }
                                >
                                  Ready
                                </Link>
                                {/* <Link
                                  to={"#"}
                                  type="submit"
                                  className="btn btn-login w-auto p-2"
                                  onClick={() =>
                                    handleMarkAsRejected(order.order_id)
                                  }
                                >
                                  Reject
                                </Link> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                            <p>No Orders available</p>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- View Bill --> */}
      <div
        className="modal fade"
        id="viewbill"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                View Order{" "}
                <span className="order-id">#{billData.book_id || "N/A"}</span>
              </h5>
              <button
                type="button"
                className="btn btn-close btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="viewbill"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row">
                  <div className="mb-3 col-12">
                    <div className="tbl-history mt-0">
                      <div className="tbl-name-date">
                        <h5>
                          Table No - <span>{billData.table_name || "N/A"}</span>
                        </h5>
                        <h6>
                          {/* <span>03 July 2024</span> | <span>10:03:32</span> */}
                          <span>{billData.datetime || "N/A"}</span>
                        </h6>
                      </div>
                      <table class="table tbl-data mb-0">
                        <thead className="text-right">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Qty</th>
                          </tr>
                        </thead>
                        <tbody className="text-right">
                          {billData.items && billData.items.length > 0 ? (
                            billData.items.map((item, index) => (
                              <tr key={index}>
                                <td>{item.item_name || "N/A"}</td>

                                <td>
                                  <Counter
                                    initialValue={item.item_qty || 0}
                                    item_id={item.item_id}
                                    order_id={selectedOrderId}
                                    onQtyChange={handleItemQtyChange} // Pass the handler
                                  />{" "}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No items found.</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot className="total-price text-right">
                          <tr>
                            <td>Total</td>
                            <td>{calculateTotalQty()}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      className="btn btn-reset"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                    >
                      Print
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End View Bill --> */}
    </div>
  );
};

export default RequestOrder;
