import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { LoginApi } from "../helper/integration";
const Signin = () => {
  const { register, handleSubmit } = useForm();
  const [cookie, setCookie, removeCookie] = useCookies(["restaurant_id"]);
  const [remember_me, setRemember_me] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Cookies:", cookie);
    const adminCookie = cookie.restaurant_id;
    console.log(adminCookie);

    if (adminCookie) {
      console.log("Admin Cookie:", adminCookie);
      navigate("/Dashboard");
      const expirationDate = new Date(adminCookie.expiry);
      if (expirationDate > new Date()) {
        navigate("/Dashboard");
      } else {
        removeCookie("restaurant_id");
      }
    }
  }, [cookie, navigate, removeCookie]);

  const OnSubmit = async (data) => {
    data.remember_me = remember_me ? true : false;
    console.log(data);

    const loginPromise = LoginApi(data); // Create the promise for the API call

    toast.promise(
      loginPromise,
      {
        loading: "Logging in...",
        success: "Login successfull",
        error: "Invalid Cradentials!",
      },
      {
        success: {
          duration: 3000,
        },
      }
    );
    try {
      const response = await loginPromise;
      console.log("API response:", response);

      if (!response || !response.restaurant_id) {
        throw new Error("API response does not contain restaurant_id");
      }

      const expirationDate = new Date();
      if (response.remember_me) {
        expirationDate.setDate(expirationDate.getDate() + 10); // 10 days
      } else {
        expirationDate.setMinutes(expirationDate.getMinutes() + 30); // 30 minutes
      }

      setCookie(
        "restaurant_id",
        {
          restaurant_id: response.restaurant_id,
          expiry: expirationDate.toISOString(),
        },
        {
          expires: expirationDate,
        }
      );
      navigate("/Dashboard");
    } catch (error) {
      console.log(error);
      //toast.error("Login failed!");
    }
  };

  const handleRememberMeChange = (e) => {
    setRemember_me(e.target.checked);
    if (!e.target.checked) {
      removeCookie("restaurant_id");
    }
  };
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="login-top">
              <div className="row align-items-center">
                <div className="col-8 top-login-text">
                  <h2>Welcome to Restaurant App ! 👋</h2>
                  <p>Please sign-in to your account and start the adventure</p>
                </div>
                <div className="col-4 text-end">
                  <img src={logo} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="signin-page">
              <form onSubmit={handleSubmit(OnSubmit)}>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("email", { required: true })}
                  />
                  <label for="floatingInput">Email</label>
                </div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("password", { required: true })}
                  />
                  <label for="floatingPassword">Password</label>
                </div>
                <div className="d-flex justify-content-between align-content-center my-3">
                  <div className="form-check remember-me">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      {...register("remember_me", { required: false })}
                      onChange={handleRememberMeChange}
                    />
                    <label className="form-check-label" for="gridCheck">
                      Remember me
                    </label>
                  </div>
                  <Link to={"EmailVerification"} className="forgot-pass">
                    Forgot Password?
                  </Link>
                </div>
                <button type="submit" className="btn btn-login">
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
