import React, { useEffect, useState } from "react";
import { EditOrdersReqApi } from "../helper/integration";
// import { useCookies } from "react-cookie";
import { toast } from "react-hot-toast";
function Counter({ initialValue, item_id, order_id, onQtyChange }) {
  const [count, setCount] = useState(Number(initialValue) || 0);

  useEffect(() => {
    // Update the count when initialValue changes
    setCount(Number(initialValue) || 0);
  }, [initialValue]);

  const updateQty = async (newCount) => {
    try {
      await toast.promise(
        EditOrdersReqApi(order_id, [
          {
            item_id: item_id,
            item_qty: newCount,
          },
        ]),
        {
          loading: "Wait few moments...",
          success: "Order updated successfully!",
          error: "Failed to update the order.",
        }
      );
      if (onQtyChange) {
        onQtyChange(newCount, item_id); // Notify parent component of the change
      }
    } catch (error) {
      console.log("Failed to Update:", error);
      console.error("Failed to Update:", error);
    }
  };

  const increment = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newCount = count + 1;
    setCount(newCount);
    updateQty(newCount);
  };

  const decrement = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newCount = Math.max(0, count - 1);
    setCount(newCount);
    updateQty(newCount);
  };

  return (
    <div className="counter-container">
      <button onClick={decrement} className="counter-button">
        -
      </button>
      <span className="counter-display">{count}</span>
      <button onClick={increment} className="counter-button">
        +
      </button>
    </div>
  );
}

export default Counter;
