import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import user from "../Image/user.png";
import {
  GetTablesApi,
  AddTableApi,
  EditTableApi,
  DeleteTableApi,
  GetOrderHistoryApi,
  GetViewBillApi,
} from "../helper/integration";
import billlogo from "../Image/bill-logo.svg";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
const AddTable = () => {
  const [AllData, setAllData] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const [SignleData, setSignleData] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [tableName, setTableName] = useState(""); // Add state for table name
  const [AllOrdersData, setAllOrdersData] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [billData, setBillData] = useState({
    items: [],
  });

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [cookies] = useCookies(["restaurant_id"]);
  const navigate = useNavigate();
  const restaurant_id = cookies.restaurant_id?.restaurant_id;
  useEffect(() => {
    const fetchedRestaurantId = cookies.restaurant_id;
    if (fetchedRestaurantId) {
      setRestaurantId(fetchedRestaurantId);
      console.log("Fetched restaurant_id:", fetchedRestaurantId);
      //navigate("/Dashboard");
    } else {
      console.log("Restaurant ID not found in cookies.", cookies);
      // Handle the case where restaurant_id is not in the cookies
      navigate("/");
    }
  }, [cookies, navigate]);

  // Fetch category data when restaurant_id or isUpdate changes
  useEffect(() => {
    (async () => {
      if (!restaurant_id) return;
      try {
        setisLoad(false);
        const data = await GetTablesApi(restaurant_id);
        console.log("Fetched tablesdata:", data);
        setAllData(data); // Ensure data is set correctly
        setSelectedTableId(data[0]?.table_id || null);
        setisLoad(true);
      } catch (error) {
        console.error("Error fetching tables data:", error);
      }
    })();
  }, [isUpdate, restaurant_id]);

  const handleOpenAddTableModal = () => {
    // Find the highest table number from the existing data
    const maxTableNumber = AllData.reduce((max, table) => {
      const tableNumber = parseInt(table.table_name, 10);
      return isNaN(tableNumber) ? max : Math.max(max, tableNumber);
    }, 0);

    // Calculate the next table number
    const nextTableNumber = maxTableNumber + 1;

    // Set default table name to the next table number
    setTableName(nextTableNumber.toString());
  };

  const handleAddTable = async (e) => {
    e.preventDefault();

    if (!tableName || !restaurant_id) {
      toast.error("Please enter table name");
      return;
    }

    const isTableNameTaken = AllData.some(
      (table) => table.table_name === tableName
    );

    // Find the highest table number from the existing data
    if (isTableNameTaken) {
      toast.error("Table name already exists");
      return;
    }
    // Create the promise for adding a table
    const addPromise = AddTableApi({
      table_name: tableName,
      restaurant_id,
    });

    // Use toast.promise to handle different states of the promise
    toast.promise(
      addPromise,
      {
        loading: "Wait a few moments...",
        success: "Table added successfully!",
        error: "An error occurred while adding the table.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000,
        },
        error: {
          duration: 5000,
        },
      }
    );
    setisUpdate();
    try {
      // Await the promise to handle additional operations after toast
      const response = await addPromise;

      if (response.status === "success") {
        // Clear the input field and trigger data refresh
        setTableName("");
        setisUpdate(true);
      } else if (
        response.status === "error" &&
        response.message === "Table already exists"
      ) {
        // Handle the specific case where the table already exists
        toast.error("Table already exists");
      } else {
        toast.error(response.message || "Failed to add table");
      }
    } catch (error) {
      // The error toast from toast.promise will handle the error message
      console.log("Error adding table:", error);
    }
  };

  const handleEditTable = async (e) => {
    e.preventDefault();

    if (!tableName || !restaurant_id || !SignleData) {
      toast.error("Please enter table name and select a table");
      return;
    }

    const updatePromise = EditTableApi({
      table_id: SignleData.table_id, // Use the selected table ID for updating
      table_name: tableName,
      restaurant_id,
    });

    toast.promise(
      updatePromise,
      {
        loading: "Wait a few moments...",
        success: "Table updated successfully!",
        error: "An error occurred while updating the table.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000, // Success message will be shown for 3 seconds
        },
        error: {
          duration: 5000, // Error message will be shown for 5 seconds
        },
      }
    );

    try {
      const response = await updatePromise;

      if (response.status === "success") {
        // Update the AllData state with the updated table data
        setAllData((prevData) =>
          prevData.map((table) =>
            table.table_id === SignleData.table_id
              ? { ...table, table_name: tableName }
              : table
          )
        );

        setTableName(""); // Clear the input field
        setisUpdate(true); // Trigger data refresh
      } else {
        toast.error(response.message || "Failed to update table");
      }
    } catch (error) {
      console.log("Error updating table:", error);
      // The error message is already handled by toast.promise, so no need to show it again
    }
  };

  const handleEditClick = (table) => {
    setSignleData(table);
    setTableName(table.table_name); // Populate the modal with existing table data
  };

  const handleDeleteClick = (table_id) => {
    setUserIdToDelete(table_id); // Set the table ID for deletion
    const deleteModal = new bootstrap.Modal(
      document.getElementById("deleteConfirmationModal")
    );
    deleteModal.show(); // Show the confirmation modal
  };
  const handleDeleteTable = async () => {
    if (!userIdToDelete) {
      toast.error("No table selected for deletion");
      return;
    }

    // Create a promise for the delete operation
    const deletePromise = DeleteTableApi(userIdToDelete);
    // Show toast messages for different states of the promise
    toast.promise(
      deletePromise,
      {
        loading: "Wait a few moments...",
        success: "Table deleted successfully!",
        error: "An error occurred while deleting the table.",
      },
      {
        loading: {
          duration: 3000,
        },
        success: {
          duration: 3000, // Success message will be shown for 3 seconds
        },
        error: {
          duration: 5000, // Error message will be shown for 5 seconds
        },
      }
    );
    setisUpdate();
    try {
      // Await the promise to get the response data
      const data = await deletePromise;

      if (data && data.status === "success") {
        setUserIdToDelete(null); // Clear the selected table ID
        setisUpdate(true); // Trigger data refresh
      } else {
        toast.error("Failed to delete table");
      }
    } catch (error) {
      // The toast.promise will handle the error message, so this is optional
      console.log("Error deleting table:", error);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const adminCookie = cookies.restaurant_id;

      if (adminCookie && adminCookie.restaurant_id) {
        try {
          const data = await GetOrderHistoryApi(adminCookie.restaurant_id);

          const filteredOrders = data.filter(
            (order) =>
              order.table_id === selectedTableId && // selectedTableId should be the current active tab's table_id
              order.status === "Accept"
          );

          const groupOrders = Object.values(
            filteredOrders.reduce((acc, order) => {
              const { order_id } = order;
              if (!acc[order_id]) {
                acc[order_id] = {
                  order_id,
                  items: [],
                  ...order,
                };
              }
              //   acc[order_id].items.push({
              //     item_id: order.item_id,
              //     item_name: order.item_name,
              //     item_qty: order.item_qty,
              //     qty_total: order.qty_total,
              //     image: order.image,
              //   });
              return acc;
            }, {})
          );
          setAllOrdersData(groupOrders);
          console.log(groupOrders);
        } catch (error) {
          console.error("Failed to fetch categories:", error);
          console.log("Failed to fetch categories:", error);
        }
      } else {
        console.error("No restaurant_id in cookies.");
      }
    };

    if (selectedTableId) {
      fetchOrders();
    }
  }, [cookies, selectedTableId]);

  const handleTableClick = (table) => {
    setSelectedTableId(table.table_id);
    setTableName(table.table_name); // Set the table name for the modal header
  };

  // useEffect(() => {
  //   const fetchBill = async () => {
  //     if (!selectedOrderId) return; // Don't fetch if no order ID is selected

  //     try {
  //       const data = await GetViewBillApi(selectedOrderId);
  //       setBillData(data);
  //     } catch (error) {
  //       console.error("Failed to fetch bill:", error);
  //     }
  //   };

  //   fetchBill();
  // }, [selectedOrderId]);

  const handleViewBillClick = (tableId) => {
    setSelectedTableId(tableId);
    const viewBillModal = new bootstrap.Modal(
      document.getElementById("viewbill")
    );
    viewBillModal.show();
  };
  useEffect(() => {
    const fetchBill = async () => {
      if (!selectedTableId) return; // Don't fetch if no order ID is selected

      try {
        const data = await GetViewBillApi(selectedTableId);
        setBillData(data[0]); // Assuming data is an array and we need the first item
        console.log(data);
      } catch (error) {
        console.error("Failed to fetch bill:", error);
      }
    };

    fetchBill();
  }, [selectedTableId]);

  useEffect(() => {
    const addTableModal = document.getElementById("addtable");
    const editTableModal = document.getElementById("edittable");
    const viewbillModal = document.getElementById("viewbill");
    const handleModalClose = () => window.location.reload();
    if (addTableModal) {
      addTableModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    if (editTableModal) {
      editTableModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    if (viewbillModal) {
      viewbillModal.addEventListener("hidden.bs.modal", handleModalClose);
    }
    // Clean up event listeners on unmount
    return () => {
      if (addTableModal) {
        addTableModal.removeEventListener("hidden.bs.modal", handleModalClose);
      }
      if (editTableModal) {
        editTableModal.removeEventListener("hidden.bs.modal", handleModalClose);
      }
      if (viewbillModal) {
        viewbillModal.addEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, []);
  return (
    <div>
      {<Sidebar />}
      <div className="asside">
        <div className="about-first">
          <div className="">
            <div className="page-heading">
              <h3>Add Table</h3>
            </div>
            <div className="bg-box">
              <div className="row">
                <div className="col-12">
                  <div className="pro-add-new text-red mb-24 mt-0 all-table-flex">
                    <p className="mb-0">All Tables</p>
                    <Link
                      to={"/Dashboard"}
                      type="submit"
                      className="btn btn-login w-auto p-2"
                      data-bs-toggle="modal"
                      data-bs-target="#addtable"
                      onClick={handleOpenAddTableModal}
                    >
                      Add Table
                    </Link>
                  </div>
                </div>
                <div className="row">
                  {AllData.map((table, index) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-6 mb-24"
                      key={index}
                    >
                      <div className="edit-delete-modal">
                        {table.status === "busy" ? (
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#vieworder"
                            //aria-selected={table.table_id === selectedTableId}
                            onClick={() => handleTableClick(table)}
                          >
                            <div className="dash-box">
                              <div className="welcome-section justify-content-center">
                                <div id="busy" className="all-tables busy">
                                  <h4>{`Table No ${table.table_name}`}</h4>
                                  <h5>{`₹ ${table.order_amount}`}</h5>
                                  <h6>
                                    {table.status.charAt(0).toUpperCase() +
                                      table.status.slice(1)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <div className="dash-box">
                            <div className="welcome-section justify-content-center">
                              <div
                                id="available"
                                className="all-tables available"
                              >
                                <h4>{`Table No ${table.table_name}`}</h4>
                                <h5>{`₹ ${table.order_amount}`}</h5>
                                <h6>
                                  {table.status.charAt(0).toUpperCase() +
                                    table.status.slice(1)}
                                </h6>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="edit-delete-icon">
                          <Link
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edittable"
                            // onClick={() => setSignleData(table)}
                            onClick={() => handleEditClick(table)}
                          >
                            <i className="fa-solid fa-pen text-success"></i>
                          </Link>
                          <Link
                            type="button"
                            onClick={() => handleDeleteClick(table.table_id)}
                          >
                            <i className="fa-solid fa-trash text-danger"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-24">
                  <Link to={"#"}>
                    <div className="dash-box">
                      <div className="welcome-section justify-content-center">
                        <div className="all-tables" id="available">
                          <h4>Table No 1</h4>
                          <h5>₹ 180</h5>
                          <h6>Available</h6>
                        </div>
                        <div className="edit-delete-icon">
                          <Link
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edittable"
                          >
                            <i className="fa-solid fa-pen text-success"></i>
                          </Link>
                          <Link>
                            <i className="fa-solid fa-trash text-danger"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Table --> */}
      <div
        className="modal fade"
        id="addtable"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Table
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row" onSubmit={handleAddTable}>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Enter Table No</label>
                    <input
                      type="text"
                      value={tableName}
                      onChange={(e) => setTableName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      className="btn btn-reset"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Add Table --> */}

      {/* <!-- Edit Table --> */}
      <div
        className="modal fade"
        id="edittable"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Table
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <form className="row" onSubmit={handleEditTable}>
                  <div className="mb-3 col-12">
                    <label className="mb-2">Enter Table No</label>
                    <input
                      type="text"
                      value={tableName}
                      onChange={(e) => setTableName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button
                      className="btn btn-reset"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End Edit Table --> */}

      {/* <!-- View Order --> */}
      <div
        className="modal fade"
        id="vieworder"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                View Order - Table No {tableName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {AllOrdersData.length > 0 ? (
                  AllOrdersData.map((order, index) => (
                    <React.Fragment key={index}>
                      <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                        <p className="mb-0">
                          {/* Placeholder for order date and time */}
                          {order.datetime} {/*<span>{order.time}</span> */}
                        </p>
                        <Link
                          to={"/Dashboard"}
                          type="submit"
                          className="btn btn-login w-auto p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#viewbill"
                          onClick={() => handleViewBillClick(order.table_id)}
                        >
                          View Bill
                        </Link>
                      </div>
                      <div className="responsive-table">
                        <table className="table">
                          <tbody>
                            {order.items.map((item, itemIndex) => (
                              <tr className="shadow-border" key={itemIndex}>
                                <td>
                                  <div className="profile-img">
                                    <img
                                      src={item.image || user} // Use the image from API or default image
                                      alt={item.item_name}
                                      className="img-fluid"
                                    />
                                    <p>{item.item_name}</p>
                                  </div>
                                </td>
                                <td>X{item.item_qty}</td>
                                <td>₹{item.qty_total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div className="pro-add-new px-0 text-red mb-24 mt-0 all-table-flex">
                    <p>No Orders available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End View Order --> */}

      {/* <!-- View Bill --> */}
      <div
        className="modal fade"
        id="viewbill"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                View Bill
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="user-details">
                <div className="bill-logo">
                  <img src={billlogo} className="img-fluid" />
                </div>
                <form className="row">
                  <div className="mb-3 col-12">
                    <div className="tbl-history">
                      <div className="tbl-name-date">
                        <h5>
                          Table No - <span>{billData.table_name || "N/A"}</span>
                        </h5>
                        <h6>
                          <span>{billData.datetime || "N/A"}</span>
                        </h6>
                      </div>
                      <table className="table tbl-data mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billData.items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.item_name}</td>
                              <td>{item.item_qty}</td>
                              <td>₹ {item.qty_total}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot className="total-price">
                          <tr>
                            <td>Total</td>
                            <td>{billData.total_qty || 0}</td>
                            <td>₹ {billData.total || 0}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="tbl-history">
                      <table className="table tbl-data mb-0">
                        <tbody>
                          <tr>
                            <td>Amount to pay</td>
                            <td>₹ {billData.total || 0}</td>
                          </tr>
                          <tr>
                            <td>GST</td>
                            <td>₹ {billData.gst_amount || 0}</td>
                          </tr>
                        </tbody>
                        <tfoot className="total-price">
                          <tr>
                            <td>Sub Total</td>
                            <td>₹ {billData.billing_total || 0}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="upload-reset-btn mb-0 justify-content-center pt-2">
                    <button className="btn btn-reset" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button
                      className="btn btn-upload me-0"
                      data-bs-dismiss="modal"
                    >
                      Print
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--End View Bill --> */}

      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
        //style={{ visibility: "visible" }} // For debugging
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">
                Confirm Deletion
              </h5>
              <button
                type="button"
                className="btn-close bg-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="py-4 text-center">
                Are you sure you want to delete this Table ?
              </h4>
              <div className="d-flex justify-content-center pt-2">
                <button
                  className="btn btn-reset me-2"
                  data-bs-dismiss="modal"
                  onClick={handleDeleteTable}
                >
                  Yes
                </button>
                <button className="btn btn-upload" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTable;
